import './App.scss';
import { Home } from './components/home/Home';
import { BrowserRouter as Router, Routes, Route, HashRouter, BrowserRouter, Navigate, useNavigate } from "react-router-dom";
import { Login } from './components/login/Login';
import { useState } from 'react';
import { useAppSelector } from './state/hooks';
import { useEffect } from 'react';
import { setLogOff } from './state/features/loginSlice';
import { useDispatch } from 'react-redux';
import { setIsMobileScreen } from './state/features/styleControlSlice';
import { CustomizeRestApi } from './util/restapi/customize';

function App() {
  const [token, setToken] = useState(localStorage.getItem('token'))
  const loginStatus = useAppSelector(state => state.login.loginStatus)
  const dispatch = useDispatch();

  useEffect(() => {
    getCustomize()
    if(+(window as any).innerWidth <= 640){
      dispatch(setIsMobileScreen(true))
    }else{
      dispatch(setIsMobileScreen(false))
    }
    dispatch(setLogOff(true)); 
    
  }, []);

  
  const getCustomize = async () => {
    try {
      const response :any = await CustomizeRestApi.customize()
      const favicon:any = document.getElementById('favicon');
      if (favicon) {
        favicon.href = response.data.customize.logo;
      }
    } catch (error) {
    }
  }

  return (
    <HashRouter>
      <Routes>
        <Route path="login" element={<Login />} />
        <Route path="/*" element={<Home />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
