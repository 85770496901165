import './DigiPopup.scss';
import { Dialog } from 'primereact/dialog';
import { InputText, } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setCancelDigiActivate, setChangedFields, setDigiPopupOpened, setDigiPopupVisibility, setFrom, setHeightVal, setPanoramaTransactStatus, setPopupSaveStatus, setSelectedLayerFields, setTransactStatus } from '../../../../../state/features/digitizationSlice';
import { useAppSelector } from '../../../../../state/hooks';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { FileUpload } from 'primereact/fileupload';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
import { AttachmentRestApi } from '../../../../../util/restapi/attachment';
import { DigiCallbackContext } from '../DigiCallbackContextProvider';
import { useTranslation } from 'react-i18next';
import { ButtonState } from '../../../../shared/button-state/ButtonState';
import React from 'react';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { ColorPicker } from 'primereact/colorpicker';
import { setButtonState, setHeightMeasureActive } from '../../../../../state/features/buttonStateSlice';
import { setFeatureAttachments,} from '../../../../../state/features/digitizationSlice';
import { setDrawState } from '../../../../../state/features/drawStateSlice';
import { DrawStateType } from '../../../../../util/model/drawState';
interface Props {
    title: string;
    from: string;
    content?: string;
    mode: any;
    canceled?:any
}
addLocale('tr', { closeText: 'kapat', prevText: 'geri', nextText: 'ileri', currentText: 'bugün', monthNames: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'], monthNamesShort: ['Oca', 'Şub', 'Mar', 'Nis', 'May', 'Haz', 'Tem', 'Ağu', 'Eyl', 'Eki', 'Kas', 'Ara'], dayNames: ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'], dayNamesShort: ['Pz', 'Pt', 'Sa', 'Ça', 'Pe', 'Cu', 'Ct'], dayNamesMin: ['Pz', 'Pt', 'Sa', 'Ça', 'Pe', 'Cu', 'Ct'], weekHeader: 'Hf', firstDay: 1, isRTL: false, showMonthAfterYear: false, yearSuffix: '', timeOnlyTitle: 'Zaman Seçiniz', timeText: 'Zaman', hourText: 'Saat', minuteText: 'Dakika', secondText: 'Saniye', ampm: false, month: 'Ay', week: 'Hafta', day: 'Gün', allDayText: 'Tüm Gün' });
export const DigiPopup = (props: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const digiCallback = useContext(DigiCallbackContext)
    const [fields, setFields] = useState<any>([])
    const [mustDisabled, setMustDisabled] = useState<boolean>(false)
    
    const enabledByMemorize: any = useAppSelector(state => state.digitization.enabledByMemorize);
    const [floatErrorVisibility, setFloatErrorVisibility] = useState<boolean>(false)
    const [hideSaveButton, setHideSaveButton] = useState<boolean>(false)
    const [attachment, setAttachment] = useState<File[]>([]);
    const selectedLayer: any = useAppSelector(state => state.layer.selectedLayer);
    const buttonState: any = useAppSelector(state => state.buttonState.buttonState)
    const featureAttachments: any = useAppSelector(state => state.digitization.featureAttachments);
    const cal = useRef(null);
    const from: string = useAppSelector(state => state.digitization.from)
    const calcFields: any = useAppSelector(state => state.digitization.calculatedFields);
    const heightVal: any = useAppSelector(state => state.digitization.heightVal);
    const [isEmailValidate, setIsEmailValidate] = useState<boolean>(true)
    const heightFieldMeasureActive: any = useAppSelector(state => state.buttonState.heightFieldMeasureActive)
    const [uid, setUid] = useState<Number>()
    useEffect(() => {
        let memorisedEntries = JSON.parse(localStorage.getItem('memorisedEntries') || '[]');
        let currentLayerId = fields[0]?.layer_id;
        let existingLayerIndex = memorisedEntries.findIndex((array: any) => array[0]?.layer_id === currentLayerId);
        // console.log("existingLayerIndex :",existingLayerIndex);
        
        let buttonVisible: boolean = true
        if (buttonState === ButtonState.ADD) {
            if (fields && fields.length > 0) {
                let updatedFields: any = [];
                if (existingLayerIndex !== -1 && enabledByMemorize) {
                    
                    let currentField = memorisedEntries.splice(existingLayerIndex, 1);
                    let flattenedMemorisedEntries = [].concat(...currentField);
                    fields.forEach((field: any) => {
                        let memorisedEntry: any = flattenedMemorisedEntries.find((entry: any) => entry.layer_id === field?.layer_id && entry.id === field?.id);
                        let updatedField = memorisedEntry ? { ...field, inputValue: memorisedEntry.inputValue } : { ...field };
                        if ((updatedField.inputValue === undefined || updatedField.inputValue === null) && (updatedField.nullable === false)) {
                            buttonVisible = false
                        }
                        if(updatedField.type==="Height"){
                            updatedField.inputValue = ""
                        }
                        updatedFields.push(updatedField);
                    });
                    setFields(updatedFields);
                    setMustDisabled(false)
                    if (!buttonVisible) {
                        setMustDisabled(true)
                    }
                }
                else {
                    setFields(fields)
                }
            }
        }
    }, [fields?.length]);

    useEffect(() => {
        if (selectedLayer) {
            // dispatch(setDigiPopupOpened(true))
            // console.log("HOPAAA");
            // console.log("DigiPopup DigiPopup");

            const filteredFields = selectedLayer.fields.filter((field: any) => field.type !== 'bbox' && field.type != 'geom' && field.type != 'Geom' && field.type !== 'Id' && field.type !== 'CreatedBy' && field.type !== 'CreatedDate' && field.type !== 'TouchBy' && field.type !== 'TouchDate')
            setMustDisabled(-1 !== filteredFields.findIndex((field: any) => !field.nullable
                &&
                field.type !== 'Length' && field.type !== 'Start_Point' && field.type !== 'End_Point' && field.type !== 'Middle_Point' && field.type !== 'Area' && field.type !== 'Location' && field.type !== 'Start_Point(X)'
                &&
                field.type !== 'End_Point(X)' && field.type !== 'Middle_Point(X)' && field.type !== 'Location(X)' && field.type !== 'Start_Point(Y)' && field.type !== 'End_Point(Y)' && field.type !== 'Middle_Point(Y)' && field.type !== 'Location(Y)'
                &&
                field.type !== 'Start_Point(Z)' && field.type !== 'End_Point(Z)' && field.type !== 'Middle_Point(Z)' && field.type !== 'Location(Z)' && field.type !== 'Height' && field.type !== 'Perimeter'
            ))
            const sortedFields = filteredFields.sort((a: any, b: any) => a.priority - b.priority);
            setFields(sortedFields);
            dispatch(setSelectedLayerFields(sortedFields))
        }
    }, [selectedLayer])

   /*  useEffect(() => {
        if (featureAttachments.attachments && featureAttachments.attachments.length > 0) {
            for (const attach of featureAttachments.attachments) {
 console.log('====================================');
 console.log(attach);
 console.log('====================================');
                var reader = new FileReader();
                reader.onload = function () {
                    var dataURL = reader.result;
                    console.log(dataURL);
                    
                    var output = document.getElementById('output');
                };
                reader.readAsDataURL(attach?.[0]);
            }


            setAttachment(attachment)
            dispatch(setFeatureAttachments(attachment))
        }
    }, [featureAttachments]) */

    const uidNotFind = (st : any) => {
        const uids : any = (window as any).drawUids ? (window as any).drawUids : [];
        if(!uids.find((el:any)=> el === st.uid)){
            return true;
        }else{
            return false;
        }
    }
    const updateUids = (st : any) => {
        const uids : any = (window as any).drawUids ? (window as any).drawUids : [];
        if(!uids.find((el:string) => el === st?.uid)){
            uids.push(st?.uid)
        }
        (window as any).drawUids = uids;
    }
    const onHide = () => {
        const btnState: string = buttonState;
        dispatch(setDigiPopupVisibility(false))
        dispatch(setCancelDigiActivate(true))
        dispatch(setFrom(""))
        if (buttonState === ButtonState.ADD /*|| buttonState === ButtonState.EDIT || buttonState === ButtonState.INFO ||
            buttonState === ButtonState.DELETE || buttonState === ButtonState.SPLIT || buttonState === ButtonState.MOVE ||
            buttonState === ButtonState.PARALLEL || buttonState === ButtonState.HOLE || buttonState === ButtonState.SELECTION || buttonState === ButtonState.VERTEX*/) {
            setTimeout(() => {
                (window as any).drawStartOfDigi = false;
                dispatch(setButtonState(btnState))
            }, 300);
        } /*else if (buttonState === ButtonState.VERTEX) {
            setTimeout(() => {
                dispatch(setButtonState(btnState))
            }, 500);
        }*/
        /** */
        dispatch(setDigiPopupOpened(false))
        dispatch(setHeightMeasureActive(false))
        if(uid){
            const panogl : any= (window as any).panogl;
            const st :any = panogl.getScalable().getMainSketchLayer().getGeomListClone().find((el:any)=> el.uid === uid)
            if(st && uidNotFind(st)){
                 panogl.getScalable().getMainSketchLayer().removeFromGlobal(st)
                 panogl.getScalable().getMainSketchLayer().refresh();
            } 
        }
        dispatch(setDrawState({ drawStateType: DrawStateType.NONE }))
    }

    useEffect(() => {
        const field: any = fields.find((field: any) => field.type === 'Height')
        if(field && heightVal){
            onChangeInput(field.id, field.name, heightVal.toString())
        }
    }, [heightVal]);

    const onChangeColor = (fieldId: string, fieldName: string, fieldValue: any, event: any, fieldType?: any) => {
        onChangeInput(fieldId, fieldName, fieldValue, event, fieldType);
    }
    const onChangeInput = (fieldId: string, fieldName: string, fieldValue: any, event?: any, fieldType?: any, fieldNullable?: any) => {
        // console.log("fieldValue :",fieldValue);
        
        if (props.title === 'Add New Feature') {
            const copyFields = [...fields]
            const findRowIndex: any = fields.findIndex((field: any) => field.id === fieldId);
            copyFields[findRowIndex] = { ...copyFields[findRowIndex], inputValue: fieldValue };
            // console.log(copyFields.filter((el:any)=> !el.nullable).findIndex((field:any)=> !field.nullable && (!field.inputValue || field.inputValue === "" || !field.hasOwnProperty('inputValue')) &&
            // field.type !== 'bbox' && field.type !== 'Geom' && field.type !== 'Id' && field.type !== 'CreatedBy' && field.type !== 'CreatedDate' && field.type !== 'TouchBy' && field.type !== 'TouchDate'
            // ));
            setMustDisabled(-1 !== copyFields.findIndex((field: any) => !field.nullable && ((field.type === 'Integer' && (field.inputValue === null || field.inputValue === undefined)) || (field.type !== 'Integer' && !field.inputValue) || field.inputValue === "" || !field.hasOwnProperty('inputValue')) &&
                field.type !== 'bbox' && field.type !== 'Geom' && field.type !== 'Id' && field.type !== 'CreatedBy' && field.type !== 'CreatedDate' && field.type !== 'TouchBy' && field.type !== 'TouchDate'
                &&
                field.type !== 'Length' && field.type !== 'Start_Point' && field.type !== 'End_Point' && field.type !== 'Middle_Point' && field.type !== 'Area' && field.type !== 'Location' && field.type !== 'Start_Point(X)'
                &&
                field.type !== 'End_Point(X)' && field.type !== 'Middle_Point(X)' && field.type !== 'Location(X)' && field.type !== 'Start_Point(Y)' && field.type !== 'End_Point(Y)' && field.type !== 'Middle_Point(Y)' && field.type !== 'Location(Y)'
                &&
                field.type !== 'Start_Point(Z)' && field.type !== 'End_Point(Z)' && field.type !== 'Middle_Point(Z)' && field.type !== 'Location(Z)' && field.type !== 'Height' && field.type !== 'Perimeter'
            )
            )

            setFields(copyFields)

            if ((fieldType === 'Email' && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(fieldValue))) {
                setIsEmailValidate(false)
                if (fieldType === 'Email' && fieldNullable && !fieldValue.length) {
                    setIsEmailValidate(true)
                }
            } else if ((fieldType === 'Email' && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(fieldValue))) {
                setIsEmailValidate(true)
            }
        }
    }

    useEffect(() => {
        const filteredField = fields.find((field: any) => field.type === "Float")
        const isdisabled = filteredField?.inputValue && parseFloat(filteredField?.inputValue).toFixed(2).split('.')[0].length > 9 ? true : false
        setFloatErrorVisibility(isdisabled)

    }, [fields])
    const onFileSelect = (e: any, field: any) => {
        
        let _totalSize = field.fileSize ? field.fileSize : 0;
        const files = [...e.files]

        files.forEach((file: any) => {
            _totalSize += file.size;
        });
        const fileSizeInMegabytes = files[0]?.size / (1024 * 1024);
        if (fileSizeInMegabytes >= 25) {
            setHideSaveButton(true)
        } else {
            setHideSaveButton(false)
            
        }

        setAttachment([...attachment, files[0]])

        let findedAttFieldIndex = fields.findIndex((attField: any) => attField.id === field.id);
        const copyFields = [...fields]
        copyFields[findedAttFieldIndex] = {
            ...copyFields[findedAttFieldIndex],
            files: attachment,
            // fileSize: _totalSize
        }
        setFields(copyFields)
    }

    const headerTemplate = (options: any) => {
        options.props.chooseOptions.label = `${t('BUTTON.Select File')}`
        options.props.chooseOptions.icon = 'none'
        const { chooseButton } = options;

        return (
            <>
                {chooseButton}
            </>
        );
    }


    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column">
                <span style={{ padding: '10px', 'fontSize': '1em', color: 'var(--text-color-secondary)' }} className="my-5">{t('BUTTON.Select File')}</span>
            </div>
        )
    }

    const itemTemplate = (file: any, props: any) => {
        return (
            <div className="flex align-items-center flex-wrap">
                <div className="item-group">
                    {file.objectURL ?
                        <img alt={file.name} role="presentation" src={file.objectURL} width={70} /> :
                        <div className='image-box'>
                            <i className="pi pi-file" style={{ 'fontSize': '2em', color: '#863564' }}></i>
                        </div>
                    }
                    <span className="flex flex-column text-left ml-3">
                        {file.name}
                    </span>
                   {/*  <Tag value={props.formatSize} className="px-3 py-2" /> */}
                    <Button type="button" icon="pi pi-times" className="p-button-sm p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(props.onRemove,file)} />
                </div>
            </div>
        )
    }

 
        const onTemplateRemove = (callback:any, file:any) => {
            callback();
        
            setTimeout(() => {
              const updatedAttachments = attachment.filter((item:any) => item !== file);
              setAttachment(updatedAttachments);
              dispatch(setFeatureAttachments(updatedAttachments));
            }, 0);
          }; 

    useEffect(() => {

        if (!isEmailValidate || mustDisabled || hideSaveButton || floatErrorVisibility) {
            return
        }
        else {
            document.addEventListener('keydown', handleKeyDown)
        }
        return () => document.removeEventListener('keydown', handleKeyDown);

    }, [isEmailValidate, mustDisabled, hideSaveButton, floatErrorVisibility, fields]);

    function handleKeyDown(event: any) {
        if (event.key === 'Enter') {
            onSubmit(event);
        }
    }

    const onSubmit = (event: any) => {
        event.preventDefault();

        dispatch(setChangedFields(fields))
        if (from === 'Map') {
            dispatch(setTransactStatus(true))
            dispatch(setPanoramaTransactStatus(false))
            dispatch(setPopupSaveStatus(true));

        }
        if (from === 'Panorama') {
            dispatch(setPanoramaTransactStatus(true))
            dispatch(setTransactStatus(false))
            dispatch(setPopupSaveStatus(true));
        }
        if (buttonState === ButtonState.ADD) {
            let memorisedEntries = JSON.parse(localStorage.getItem('memorisedEntries') || '[]');
            let currentLayerId = fields[0]?.layer_id;
            let existingLayerIndex = memorisedEntries.findIndex((array: any) => array[0]?.layer_id === currentLayerId);
            if (existingLayerIndex !== -1) {
                memorisedEntries.splice(existingLayerIndex, 1);
            }
            memorisedEntries.push(fields);
            localStorage.setItem('memorisedEntries', JSON.stringify(memorisedEntries));
        }        
        onHide();
        //attachment için
        digiCallback.setCallbackFunction((params) => {
            return new Promise(async resolve => {
               /*  if (attachment && attachment.length > 0) {
                    for (const file of attachment) {
                        const f = new FormData()
                        f.append('file', file)
                        AttachmentRestApi.addAttachment(selectedLayer.id, params, f).then((res: any) => {
                        }).catch((err: any) => { console.error(err); })
                        resolve(true)
                    }
                } */
                for (const file of attachment) {
                    const fileSizeInMegabytes = file?.size / (1024 * 1024)
              
                    if (fileSizeInMegabytes < 25) {
                       
                        const f = new FormData()
                        f.append('file', file)
                         AttachmentRestApi.addAttachment(selectedLayer.id, params,  f).then((res: any) => {
                        if (res) {
                            setAttachment([])
                        }
                    }).catch((err: any) => { console.error(err); }) 
                    resolve(true)
                    } else {
                        setAttachment([])
                    }
                }
            })
        })
    }
    const deleteFile =  (e:any) => {
        if (e) {
            dispatch(setFeatureAttachments([]))
        }
    }
    return (
        <><Dialog className='digi-popup' header={`${selectedLayer.alias}`} visible={true} onHide={onHide}
            draggable={true} resizable={true} position='center' modal={false}>
            {(buttonState === ButtonState.VERTEX || buttonState === ButtonState.MOVE || buttonState === ButtonState.HOLE) &&
                <>
                    <p>{props.content}</p><div className='buttons'>
                        <Button label={`${t('BUTTON.Cancel')}`} onClick={
                            ()=>{
                                if(props.canceled){
                                    props.canceled()
                                }
                                onHide()
                            }}></Button>
                        <Button label={`${t('BUTTON.Save')}`} className='save-button' onClick={onSubmit}></Button>
                    </div>
                </>
            }
            {/*  */}
            {props.mode === 'INSERT' && <form autoComplete='off' onSubmit={onSubmit} className='form'>
                {fields.map((field: any, index: any) => <React.Fragment key={field.id}>

                    {field.type === 'Text' &&
                        <>
                            {!field.nullable ? <label htmlFor='field'>{field.alias} *</label> : <label htmlFor='field'>{field.alias}</label>}
                            <InputText name={field.name} key={field.id} id={field.alias} maxLength={255} value={field.inputValue} onChange={(e) => onChangeInput(field.id, field.name, e.target.value, e)} required={!field.nullable} className='input-style' />
                        </>}
                    {field.type === 'Email' &&
                        <>
                            {!field.nullable ? <label htmlFor='field'>{field.alias} *</label> : <label htmlFor='field'>{field.alias}</label>}
                            <InputText name={field.name} keyfilter="email" key={field.id} id={field.alias} maxLength={255} value={field.inputValue} onChange={(e) => onChangeInput(field.id, field.name, e.target.value, e, field.type, field.nullable)} required={!field.nullable} className='input-style' />
                            {!isEmailValidate && <label className={'p-error'}>{t('MESSAGE.InvalidEmail')}</label>}
                        </>}
                    {field.type === 'Height' &&
                        <>
                            {!field.nullable ? <label htmlFor='field'>{field.alias} *</label> : <label htmlFor='field'>{field.alias}</label>}
                            <div style={{ display: 'flex', maxWidth: '320px !important' }}>
                                <InputText
                                    name={field.name} key={field.id} id={field.alias} value={field.inputValue} 
                                    required={!field.nullable} className='input-style'
                                />
                                <Button 
                                    style={{ marginTop: '8px'}}
                                    id='idPANORAMA_TRIANGLE_PC'
                                    className={heightFieldMeasureActive ? 'active-button' : ''}
                                    onClick={(e:any) => {
                                        e.preventDefault()
                                        if(!heightFieldMeasureActive){
                                            dispatch(setHeightMeasureActive(true))
                                            const panogl : any= (window as any).panogl;
                                            const st:any = panogl.getScalable().getMainSketchLayer().getGeomListClone()[0]
                                            setUid(st?.uid);
                                            updateUids(st)
                                        }
                                    }}
                                    tooltipOptions={{ position: 'bottom' }}
                                    >
                                    <img src={'icons/triangle-draw.svg'} alt="" />
                                </Button>
                            </div>
                        </>}
                    {field.type === 'Telephone' &&
                        <>
                            {!field.nullable ? <label htmlFor='field'>{field.alias} *</label> : <label htmlFor='field'>{field.alias}</label>}
                            <InputMask name={field.name} key={field.id} id={field.alias} mask="9(999)-999-99-99" maxLength={11} value={field.inputValue} onChange={(e) => onChangeInput(field.id, field.name, e.target.value, e)} required={!field.nullable} className='input-style' />
                        </>

                    }
                    {field.type === 'CreatedBy' &&
                        <>
                            {!field.nullable ? <label htmlFor='field'>{field.alias} *</label> : <label htmlFor='field'>{field.alias}</label>}
                            <InputText disabled name={field.name} key={field.id} id={field.alias} value={field.inputValue} onChange={(e) => onChangeInput(field.id, field.name, e.target.value, e)} required={!field.nullable} maxLength={250} className='input-style' />
                        </>}
                    {field.type === 'CreatedDate' &&
                        <>
                            {!field.nullable ? <label htmlFor='field'>{field.alias} *</label> : <label htmlFor='field'>{field.alias}</label>}
                            <InputText disabled name={field.name} key={field.id} id={field.alias} value={field.inputValue} onChange={(e) => onChangeInput(field.id, field.name, e.target.value, e)} required={!field.nullable} maxLength={250} className='input-style' />
                        </>}
                    {field.type === 'Id' &&
                        <>
                            {!field.nullable ? <label htmlFor='field'>{field.alias} *</label> : <label htmlFor='field'>{field.alias}</label>}
                            <InputText disabled name={field.name} key={field.id} id={field.alias} value={field.inputValue} onChange={(e) => onChangeInput(field.id, field.name, e.target.value, e)} required={!field.nullable} maxLength={250} className='input-style' />
                        </>}
                    {field && (field.type === 'Float') &&
                        <>
                            {!field.nullable ? <label htmlFor='minmaxfraction'>{field.alias} *</label> : <label htmlFor='minmaxfraction'>{field.alias}</label>}
                            <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} name={field.name} inputId='minmaxfraction'
                                onKeyDown={(e: any) => {
                                    const selection = e.target.value.substring(
                                        e.target.selectionStart,
                                        e.target.selectionEnd,
                                    );
                                    const deger = field.inputValue
                                    if ((selection === "" && deger && deger.toString().split('.')[0].toString().length === 9 && e.target.selectionEnd < 12) || (e.target.selectionStart === 11 && e.target.selectionEnd === 14)) {
                                        if (!isNaN(+e.key)) {
                                            e.preventDefault()
                                        }
                                    }
                                }}

                                id={field.alias} value={field.inputValue} required={!field.nullable} onChange={(e: any) => onChangeInput(field.id, field.name, e.value, e)} className='input-style' />
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <small hidden={!(field.inputValue && parseFloat(field.inputValue).toFixed(2).split('.')[0].length > 9)} className="p-error">Maksimum karakterden fazla girildi</small>
                            </div>
                        </>}
                    {field && (field.type === 'Integer') &&
                        <>
                            {!field.nullable ? <label htmlFor='int'>{field.alias} *</label> : <label htmlFor='int'>{field.alias}</label>}
                            <InputNumber
                            key={field.id}
                                name={field.name}
                                inputId='int'
                                /*    mode='decimal' */
                                minFractionDigits={0} maxFractionDigits={2}
                                id={field.alias}
                                value={field.inputValue}
                                required={!field.nullable}
                                onKeyDown={(e: any) => {
                                    const selection = e.target.value.substring(
                                        e.target.selectionStart,
                                        e.target.selectionEnd,
                                    );
                                    const deger = field.inputValue
                                    if (selection === "" && deger && deger.toString().length === 9) {
                                        if (!isNaN(+e.key)) {
                                            e.preventDefault()
                                        }
                                    }
                                }}

                                onChange={(e: any) => {
                                    // console.log("onChange : ",e);
                                    onChangeInput(field.id, field.name, e.value, e)
                                }}
                                className='input-style'
                            />
                        </>}
                    {field && field.type === 'Domain' &&
                        //burası
                        <>
                            {!field.nullable ? <label htmlFor='domain'>{field.alias} *</label> : <label htmlFor='domain'>{field.alias}</label>}
                            <Dropdown key={field.id} showClear filter name={field.name} className='input-style' value={field.inputValue} required={!field.nullable ? !field.inputValue : false} options={field.domain} optionLabel='desc' optionValue='id' onChange={(e: any) => onChangeInput(field.id, field.name, e.value, e, e)} style={{ height: '36px', display: 'flex', alignItems: 'center' }} />
                        </>}

                    {field && (field.type === 'Color') &&
                        <>
                            {!field.nullable ? <label htmlFor='color'>{field.alias} *</label> : <label htmlFor='color'>{field.alias}</label>}
                            <div style={{ display: 'flex', maxWidth: '320px !important' }}>
                                <ColorPicker key={field.id} style={{ minWidth: '50px !important', maxWidth: '50px !important', paddingRight: '16px' }} format="hex" name={field.name} inputId='color' id={field.alias} value={field.inputValue} required={!field.nullable} onChange={(e: any) => onChangeInput(field.id, field.name, e.value, e)} className='color-style' />

                                {/* <InputNumber  style={{ minWidth: '275px'}}
                                    name="colorField" //{field.name} 
                                    key="999color" //{field.id} 
                                    id="colorFieldId" //{field.alias} 

                                    value={field.inputValue ? field.inputValue : ""} 
                                    required={!field.nullable}
                                    onKeyDown={(e: any) => {
                                     
                                        const deger = field.inputValue
                                        if (deger && deger.toString().length === 6) {
                                            if (!isNaN(+e.key)) {
                                                e.preventDefault()
                                            }
                                        }
                                    }}
                                    onChange={(e) =>  onChangeColor(field.id, field.name, String(e.value), e)} 
                                    className='input-style'
                                /> */}

                                <InputText
                                    // style={{ minWidth: '275px'}}
                                    // disabled 
                                    key={"colorField"}
                                    name="colorField" //{field.name} 
                                    //key="999color" //{field.id} 
                                    id="colorFieldId" //{field.alias} 
                                    value={field.inputValue ? field.inputValue : "ff0000"}
                                    onChange={(e) => onChangeColor(field.id, field.name, e.target.value, e)} /* */
                                    onKeyDown={(e: any) => {
                                        if (e.code === "ArrowUp") {
                                            const val = Number("0x" + e.target.value) + 1;
                                          /*   console.log("parseInt :", parseInt(String("0x" + val), 10)); */
                                            const hexVal = val.toString(16);
                                            onChangeColor(field.id, field.name, hexVal, e)

                                        } else if (e.code === "ArrowDown") {
                                            const val = Number("0x" + e.target.value) - 1;
                                           /*  console.log("val : ", val);
                                            console.log("parseInt :", parseInt(String("0x" + val), 10)); */
                                            const hexVal = val.toString(16);
                                            onChangeColor(field.id, field.name, hexVal, e)
                                        }
                                        /* const deger = field.inputValue
                                        if (deger && deger.toString().length === 6) {
                                            if (!isNaN(+e.key)) {
                                                e.preventDefault()
                                            }
                                        } */
                                    }}
                                    required={!field.nullable}
                                    maxLength={6}
                                    className='color-style' />
                            </div>

                        </>}

                    {field && (field.type === 'Date') &&
                        <>
                            {!field.nullable ? <label htmlFor='date'>{field.alias} *</label> : <label htmlFor='date'>{field.alias}</label>}

                            <Calendar
                            key={field.id}
                                locale="tr"
                                className={`.p-calendar .p-inputtext input-style`}
                                name={field.name}
                                value={field.inputValue}
                                onChange={(e: any) => {
                                    onChangeInput(field.id, field.name,
                                        new Date(e.value).getFullYear() + '-' +
                                        ('0' + (new Date(e.value).getMonth() + 1)).slice(-2) + '-' +
                                        ('0' + new Date(e.value).getDate()).slice(-2), e)
                                }}
                                dateFormat="dd-mm-yy"
                                readOnlyInput
                                placeholder='Başlangıç Tarihi Seçiniz'
                                ref={cal}
                                id={field.alias}
                                required={!field.nullable}
                            >
                            </Calendar>
                        </>}
                    {field && field.type === 'Attachment' &&
                        <>
                            {!field.nullable ? <label htmlFor='domain'>{field.alias} *</label> : <label htmlFor='domain'>{field.alias}</label>}
                            <FileUpload key={field.id} name="demo" headerTemplate={headerTemplate} emptyTemplate={emptyTemplate} itemTemplate={itemTemplate} accept="/*" maxFileSize={25000000} onSelect={(e) => onFileSelect(e, field)} className='input-style' />
                            {featureAttachments && featureAttachments.attachments?.length > 0 && featureAttachments.attachments?.map((attach: any, i: any) =>
                                <div className='attach'>
                                    <>
                                        <img className='digi-screenshot-img' src={attach ? attach : 'customize-icons/file.svg'} alt='' style={{ maxWidth: '900px' }} />
                                        <div className='button-container'>
                                            <a className='anim anim-from-left' onClick={(e) => deleteFile(e)}>
                                                {t('BUTTON.DELETE')}
                                            </a>
                                        </div>
                                    </>
                                </div>
                            )
                            }
                        </>}
                    {(field.type === 'Length') &&
                        <>
                            {!field.nullable ? <label htmlFor='field'>{field.alias} *</label> : <label htmlFor='field'>{field.alias}</label>}
                            <InputText  disabled name={field.name} key={field.id} id={field.alias} value={calcFields.length || ''} required={!field.nullable} maxLength={250} className='input-style' />
                        </>}

                    {(field.type === 'Start_Point') &&
                        <>
                            {!field.nullable ? <label htmlFor='field'>{field.alias} *</label> : <label htmlFor='field'>{field.alias}</label>}
                            <InputText disabled name={field.name} key={field.id} id={field.alias} value={calcFields.start_Point || ''} required={!field.nullable} maxLength={250} className='input-style' />
                        </>}

                    {(field.type === 'Start_Point(X)') &&
                        <>
                            {!field.nullable ? <label htmlFor='field'>{field.alias} *</label> : <label htmlFor='field'>{field.alias}</label>}
                            <InputText disabled name={field.name} key={field.id} id={field.alias} value={calcFields.start_Point_X || ''} required={!field.nullable} maxLength={250} className='input-style' />
                        </>}

                    {(field.type === 'Start_Point(Y)') &&
                        <>
                            {!field.nullable ? <label htmlFor='field'>{field.alias} *</label> : <label htmlFor='field'>{field.alias}</label>}
                            <InputText disabled name={field.name} key={field.id} id={field.alias} value={calcFields.start_Point_Y || ''} required={!field.nullable} maxLength={250} className='input-style' />
                        </>}

                    {(field.type === 'Start_Point(Z)') &&
                        <>
                            {!field.nullable ? <label htmlFor='field'>{field.alias} *</label> : <label htmlFor='field'>{field.alias}</label>}
                            <InputText disabled name={field.name} key={field.id} id={field.alias} value={calcFields.start_Point_Z || ''} required={!field.nullable} maxLength={250} className='input-style' />
                        </>}

                    {(field.type === 'Middle_Point') &&
                        <>
                            {!field.nullable ? <label htmlFor='field'>{field.alias} *</label> : <label htmlFor='field'>{field.alias}</label>}
                            <InputText disabled name={field.name} key={field.id} id={field.alias} value={calcFields.Middle_Point || ''} required={!field.nullable} maxLength={250} className='input-style' />
                        </>}

                    {(field.type === 'Middle_Point(X)') &&
                        <>
                            {!field.nullable ? <label htmlFor='field'>{field.alias} *</label> : <label htmlFor='field'>{field.alias}</label>}
                            <InputText disabled name={field.name} key={field.id} id={field.alias} value={calcFields.Middle_Point_X || ''} required={!field.nullable} maxLength={250} className='input-style' />
                        </>}

                    {(field.type === 'Middle_Point(Y)') &&
                        <>
                            {!field.nullable ? <label htmlFor='field'>{field.alias} *</label> : <label htmlFor='field'>{field.alias}</label>}
                            <InputText disabled name={field.name} key={field.id} id={field.alias} value={calcFields.Middle_Point_Y || ''} required={!field.nullable} maxLength={250} className='input-style' />
                        </>}

                    {(field.type === 'Middle_Point(Z)') &&
                        <>
                            {!field.nullable ? <label htmlFor='field'>{field.alias} *</label> : <label htmlFor='field'>{field.alias}</label>}
                            <InputText disabled name={field.name} key={field.id} id={field.alias} value={calcFields.Middle_Point_Z || ''} required={!field.nullable} maxLength={250} className='input-style' />
                        </>}

                    {(field.type === 'End_Point') &&
                        <>
                            {!field.nullable ? <label htmlFor='field'>{field.alias} *</label> : <label htmlFor='field'>{field.alias}</label>}
                            <InputText disabled name={field.name} key={field.id} id={field.alias} value={calcFields.End_Point || ''} required={!field.nullable} maxLength={250} className='input-style' />
                        </>}

                    {(field.type === 'End_Point(X)') &&
                        <>
                            {!field.nullable ? <label htmlFor='field'>{field.alias} *</label> : <label htmlFor='field'>{field.alias}</label>}
                            <InputText disabled name={field.name} key={field.id} id={field.alias} value={calcFields.End_Point_X || ''} required={!field.nullable} maxLength={250} className='input-style' />
                        </>}

                    {(field.type === 'End_Point(Y)') &&
                        <>
                            {!field.nullable ? <label htmlFor='field'>{field.alias} *</label> : <label htmlFor='field'>{field.alias}</label>}
                            <InputText disabled name={field.name} key={field.id} id={field.alias} value={calcFields.End_Point_Y || ''} required={!field.nullable} maxLength={250} className='input-style' />
                        </>}

                    {(field.type === 'End_Point(Z)') &&
                        <>
                            {!field.nullable ? <label htmlFor='field'>{field.alias} *</label> : <label htmlFor='field'>{field.alias}</label>}
                            <InputText disabled name={field.name} key={field.id} id={field.alias} value={calcFields.End_Point_Z || ''} required={!field.nullable} maxLength={250} className='input-style' />
                        </>}

                    {(field.type === 'Area') &&
                        <>
                            {!field.nullable ? <label htmlFor='field'>{field.alias} *</label> : <label htmlFor='field'>{field.alias}</label>}
                            <InputText disabled name={field.name} key={field.id} id={field.alias} value={calcFields.Area || ''} required={!field.nullable} maxLength={250} className='input-style' />
                        </>}

                    {(field.type === 'Perimeter') &&
                        <>
                            {!field.nullable ? <label htmlFor='field'>{field.alias} *</label> : <label htmlFor='field'>{field.alias}</label>}
                            <InputText disabled name={field.name} key={field.id} id={field.alias} value={calcFields.perimeter || ''} required={!field.nullable} maxLength={250} className='input-style' />
                        </>}

                    {(field.type === 'Location') &&
                        <>
                            {!field.nullable ? <label htmlFor='field'>{field.alias} *</label> : <label htmlFor='field'>{field.alias}</label>}
                            <InputText disabled name={field.name} key={field.id} id={field.alias} value={calcFields.location || ''} required={!field.nullable} maxLength={250} className='input-style' />
                        </>}

                    {(field.type === 'Location(X)') &&
                        <>
                            {!field.nullable ? <label htmlFor='field'>{field.alias} *</label> : <label htmlFor='field'>{field.alias}</label>}
                            <InputText disabled name={field.name} key={field.id} id={field.alias} value={calcFields.locationX || ''} required={!field.nullable} maxLength={250} className='input-style' />
                        </>}

                    {(field.type === 'Location(Y)') &&
                        <>
                            {!field.nullable ? <label htmlFor='field'>{field.alias} *</label> : <label htmlFor='field'>{field.alias}</label>}
                            <InputText disabled name={field.name} key={field.id} id={field.alias} value={calcFields.locationY || ''} required={!field.nullable} maxLength={250} className='input-style' />
                        </>}
                    {(field.type === 'Location(Z)') &&
                        <>
                            {!field.nullable ? <label htmlFor='field'>{field.alias} *</label> : <label htmlFor='field'>{field.alias}</label>}
                            <InputText disabled name={field.name} key={field.id} id={field.alias} value={calcFields.locationZ || ''} required={!field.nullable} maxLength={250} className='input-style' />
                        </>}

                </React.Fragment>
                )}
                <div className='buttons'>
                    <Button type={'button'} label={`${t('BUTTON.Cancel')}`} onClick={onHide}></Button>
                    <Button disabled={!isEmailValidate || mustDisabled || hideSaveButton || floatErrorVisibility} label={`${t('BUTTON.Save')}`} type="submit" className='save-button'></Button>
                </div>
            </form>}
        </Dialog></>

    )
}


