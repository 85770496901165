import Draw, { DrawEvent } from 'ol/interaction/Draw';
import { useContext, useEffect, useRef, useState } from 'react'
import { MapContext } from '../MapContext'
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import Text from 'ol/style/Text';
import { never } from 'ol/events/condition';
import { setDrawState } from '../../../../../state/features/drawStateSlice';
import { DrawStateType } from '../../../../../util/model/drawState';
import { useDispatch } from 'react-redux';
import { WKT } from 'ol/format';
import { setMapCoordinates } from '../../../../../state/features/coordinatesSlice';
import { getArea } from 'ol/sphere';
import { setButtonState } from '../../../../../state/features/buttonStateSlice';
import { ButtonState } from '../../../../shared/button-state/ButtonState';
import { MultiPolygon } from 'ol/geom';
import { GeomDataHolder } from '../../../panorama-container/easy-pano-imports';
import { useAppSelector } from '../../../../../state/hooks';
import Overlay from 'ol/Overlay';
import { LineString } from 'ol/geom';
import { getLength } from 'ol/sphere';
import "./Draw.scss";
interface Props {
    source: any,
    overlays: React.MutableRefObject<Overlay[]>;
}

export const DrawPolygon = (props: Props) => {
    const map = useContext(MapContext);
    const dispatch = useDispatch();
    const [labelText, setLabelText] = useState<any>()
    const isMobileScreen: number = useAppSelector((state: any) => state.styleControl.isMobileScreen)
    const overlays = props.overlays 
    useEffect(() => {

        return ()=>{
            const intrsDraw :any = (window as any).olMap.getInteractions().getArray().find((interaction:any)=> interaction instanceof Draw)
            // console.log(intrsDraw);
    
            intrsDraw.finishDrawing();
            if(isMobileScreen){
     
            }
        }

    }, []);


    
    useEffect(() => {
        const createLengthOverlay = () => {
            const element = document.createElement('div');
            element.className = 'length-label';
            return new Overlay({
                element,
                positioning: 'center-center',
                stopEvent: false,
                offset: [0, -10],
            });
        };

        const drawStyle = new Style({
            fill: new Fill({
                color: '#64b5f640',
            }),
            stroke: new Stroke({
                color: '#3ea1f1',
                width: 3,
            }),
            image: new CircleStyle({
                radius: 5,
                fill: new Fill({
                    color: '#3ea1f1',
                }),
            }),
        });

        const draw = new Draw({
            source:props.source,
            type: 'Polygon',
            stopClick: true,
            style: drawStyle,
            finishCondition: never,
                condition: function (e: any) {
                    // sadece solla çizim yapıyor ve sağla bitiriyor
                    if (e.originalEvent.buttons === 1) {
                        return true;
                    } else if (e.originalEvent.buttons === 2) {
                        if (!labelText) {
                            const buttonState = (window as any)?.buttonState;
                            const drawStartOfDigi = (window as any)?.drawStartOfDigi;
                
                            if (!(buttonState === ButtonState.ADD && drawStartOfDigi) && buttonState !== ButtonState.EDIT) {
                                if (typeof setButtonState === 'function') {
                                    dispatch(setButtonState(ButtonState.NONE));
                                }
                            }
                            if (typeof setDrawState === 'function') {                                
                                dispatch(setDrawState({ drawStateType: DrawStateType.NONE }));
                            }
                        }
                        draw.finishDrawing();
                        return false;
                    } else {
                        return false;
                    }
                }
                
        });
   
    let sketch: any = null;

   draw.on('drawstart', (event: any) => {
    if (event) {
      sketch = event.feature;  
      if (sketch) {
        sketch.getGeometry().on('change', (event: any) => {
          const geom = event.target;
          const coordinates = geom.getCoordinates()[0];
  
          
          overlays.current.forEach((overlay) => map.removeOverlay(overlay));
            overlays.current.length = 0;
  
          for (let i = 0; i < coordinates.length - 1; i++) {
            const start = coordinates[i];
            const end = coordinates[i + 1];
            const lineString = new LineString([start, end]);
            const segmentLength = getLength(lineString);
            const midPoint = [(start[0] + end[0]) / 2, (start[1] + end[1]) / 2];
            const overlay = createLengthOverlay();
            overlay.setPosition(midPoint);
            const element = overlay.getElement();
            if (element) {
              element.innerHTML = `${segmentLength.toFixed(2)} m`;
            }
            
            map.addOverlay(overlay);
            overlays?.current.push(overlay);
          }
        });
      }
    }
  });
        
        map.addInteraction(draw);
        drawEnd(draw);
        return () => {
            map.removeInteraction(draw);
        };
    }, [map]);


    const drawEnd = (draw: Draw) => {
        draw.on('drawend', (event: any) => {
            const _geom = event.feature?.getGeometry().clone();
            const label = getArea(_geom)//.toFixed(2) + 'm';

            setLabelText(label)
            if (label) {

                let geom;
                const wkt = new WKT();

                const _geomClone: any = event.feature.getGeometry().clone()
                geom = new MultiPolygon([_geomClone.transform('EPSG:3857', 'EPSG:4326').getCoordinates()]);
                const geomWktLine = wkt.writeGeometry(geom);
                geom.transform('EPSG:4326', 'EPSG:3857');
                const gdh: any = GeomDataHolder.fromWKT(geomWktLine);
                (window as any).panogl.getScalable().getMainSketchLayer().addGlobal(gdh);
                (window as any).panogl.getScalable().getMainSketchLayer().refresh();
                setTextAfterDrawEnd(event, gdh.attributes._areaSize);
                (window as any).panogl.getScalable().getMainSketchLayer().removeFromGlobal(gdh);
                (window as any).panogl.getScalable().getMainSketchLayer().refresh();
                // setTextAfterDrawEnd(event, label)
            }
        });
    }

    /**
     * Çizim bittikten sonra ölçüm değerini çizilen nesnenin üzerine yazar. 
     */
    const setTextAfterDrawEnd = (event: DrawEvent, polygonArea: any) => {
        const feature = event.feature;
        polygonArea = polygonArea//.toFixed(2);

        event.feature.setStyle(new Style({
            fill: new Fill({
                color: '#64b5f640',
            }),
            stroke: new Stroke({
                color: '#3ea1f1',
                width: 4,
            }),
            image: new CircleStyle({
                radius: 5,
                fill: new Fill({
                    color: '#3ea1f1',
                }),
            }),
            text: new Text({
                text: `${polygonArea}`,
                overflow: true,
                font: "bold 15px 'Open Sans', 'Arial Unicode MS', 'sans-serif'",
                placement: 'point',
                offsetY: -15,
                fill: new Fill({
                    color: 'white',
                }),
                stroke: new Stroke({
                    color: 'black',
                    width: 3,
                }),
                rotateWithView: true
            })
        }));

        //aynı çizimi panorama üzerinde de göstermek için
        const wkt = new WKT();
        const wktFeature = wkt.writeFeature(feature)

        const result = {
            type: 'Point',
            feature: wktFeature,
            text: `${polygonArea}`
        }

        dispatch(setMapCoordinates(result))
    }
    return (
        <></>
    )
}
