import './PanoramaContainer.scss';
import { Tooltip } from 'primereact/tooltip';
import { useEffect, useState, useRef } from 'react';
import { DrawPlugin, PanoGL, ScalablePlugin, SoftTextPlugin, PanoromicController, AnkaDraw, PointCloudPlugin, MultiMeasurement, GeomDataHolder, GeoServerWFSLayer, AnkaScalable, Three, AnkaPanAPI, ProfilePlugin } from './easy-pano-imports';
import { useAppSelector } from '../../../state/hooks';
import { useDispatch } from 'react-redux';
import { transform } from 'ol/proj';
import { setPanoramaInfo, setPanoramaCoordinate, setViewAngle, setPointerCoordinate, setIsPanoMiniScreen, setViewAngle2, setViewAngle3, setCoordinateForPano2, setCoordinateForPano3, setCursorCoordinate, setCursorVisible, setDrawUids, setPanoByPanogps, setEvent } from '../../../state/features/panoramaSlice';
import { Checkbox } from 'primereact/checkbox';
import { DrawStateType, DrawStatesPC, MultiDrawTypes } from '../../../util/model/drawState';
import { setDrawState } from '../../../state/features/drawStateSlice';
import { setPanoCoordinates } from '../../../state/features/coordinatesSlice';
import Point from 'ol/geom/Point';
import { Feature } from 'ol';
import GeoJSON from 'ol/format/GeoJSON';
import { LineString, Polygon } from 'ol/geom';
import { WKT } from 'ol/format';
import { FeatureRestApi } from '../../../util/restapi/features';
import { setSelectedFeature, setVertexFeature } from '../../../state/features/selectedFeatureSlice';
import { ButtonState } from '../../shared/button-state/ButtonState';
import { DigiPopup } from '../shared/digitiziation/digi-popup/DigiPopup';
import { setCalculatedFields, setCancelDigiActivate, setDigiPopupVisibility, setFrom, setHeightVal, setPanoramaEditStatus } from '../../../state/features/digitizationSlice';
import { Mode, TransactWfs } from '../shared/digitiziation/TransactWfs';
import { setButtonState, setHeightMeasureActive } from '../../../state/features/buttonStateSlice';
import { Button } from 'primereact/button';
import { Slider } from 'primereact/slider';
import { InputNumber } from 'primereact/inputnumber';
import axios from 'axios';
import { RootState } from '../../../state/store';
import { Field, Project } from '../../../util/model/project';
import { Toast } from 'primereact/toast';
import { RoadProfile } from './road-profile/RoadProfile';
import { useTranslation } from 'react-i18next';
import Splitter from "m-react-splitters";
import { useLocation } from 'react-router-dom';
import { PointCloudRestApi } from '../../../util/restapi/pointCloud';
import calculateResult from '../shared/digitiziation/digi-draw/calculateResult';
import { setGoToSelection } from '../../../state/features/tableSlice';
import { setSelectedLayer, setSelectedLayerGeoserverUrl, setWorkspace } from '../../../state/features/layerSlice';
import proj4 from 'proj4';
import { setIsPanoScreen } from '../../../state/features/styleControlSlice';

export enum Sliders {
    HEADING = 'Heading',
    ROLL = 'Roll',
    PITCH = 'Pitch',
    CAMERAHEIGHT = 'Camera Height'
}

export default function PanoramaContainer({ showMessage }: any) {
    const { REACT_APP_BASE_URL } = process.env
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const toast = useRef<any>();
    const [brightness, setBrightness] = useState<any>(25);
    const [speed, setSpeed] = useState<any>(106);
    const [videoSpeedValue, setVideoSpeedValue] = useState<any>(1000);
    const [myInterval, setMyInterval] = useState<any>(null);
    const [opacity, setOpacity] = useState<any>(100);
    const [pSize, setpSize] = useState<any>(10);
    const [view, setView] = useState<any>(2700);
    const [lidarChecked, setLidarChecked] = useState<boolean>(false);
    const [brightnessVisibility, setBrightnessVisibility] = useState<Boolean>(false);
    const [videoStart, setVideoStart] = useState<Boolean>(false);
    const [shareBox, setShareBox] = useState<Boolean>(false);
    const [viewVisibility, setViewVisibility] = useState<Boolean>(false);
    const [lidarVisibility, setLidarVisibility] = useState<Boolean>(false);
    const [pano, setPano] = useState<any>()
    const [panoDiv2, setPanoDiv2] = useState<any>()
    const [panoDiv3, setPanoDiv3] = useState<any>()
    const [draw, setDraw] = useState<any>()
    const [scable, setScable] = useState<any>()
    const [pointCloudPlugin_, setPointCloudPlugin] = useState<any>()
    const [multiMeasurePlugin, setMultiMeasurePlugin] = useState<any>()
    const [profilePlugin, setProfilePlugin] = useState<any>()
    const [coordinate, setCoordinate] = useState<any>()
    const [drawEnd, setDrawEnd] = useState<boolean>(false)
    const [chartVisibility, setChartVisibility] = useState<boolean>(false)
    const [digiOlFeature, setDigiOlFeature] = useState<any>(null)
    const [featureType, setFeatureType] = useState<any>(null)
    const [updatedFeatureType, setUpdatedFeatureType] = useState<any>(null)
    const [featureCoordinates, setFeatureCoordinates] = useState<any>(null)
    const [updatedFeatureCoordinates, setUpdatedFeatureCoordinates] = useState<any>(null)
    const [updatedOlFeature, setUpdatedOlFeature] = useState<any>(null)
    const [selectedGdhFeature, setSelectedGdhFeature] = useState<any>(null)
    const [closestPoints, setClosestPoints] = useState<any>([])
    const [settingsVisibility, setSettingsVisibility] = useState<boolean>(false)
    const [wfsLayer, setWfsLayer] = useState<any>(null)
    const [lastDirname, setLastDirname] = useState<string>("")
    const projection = useAppSelector(state => state.panorama.projection);
    const clickedMapCoordinate = useAppSelector((state) => state.panorama.mapCoordinate);
    const drawState = useAppSelector((state) => state.drawState.value);
    const mapCoordinates = useAppSelector((state) => state.coordinates.mapCoordinates);
    const buttonState: any = useAppSelector(state => state.buttonState.buttonState)
    const selectedLayer: any = useAppSelector(state => state.layer.selectedLayer)
    const digiPopupVisibility = useAppSelector((state) => state.digitization.digiPopupVisibility);
    const panoramaTransactStatus = useAppSelector(state => state.digitization.panoramaTransactStatus)
    const transactStatus = useAppSelector(state => state.digitization.transactStatus)
    const styles: any = useAppSelector(state => state.layer.styles)
    const cancelDigiActivate = useAppSelector((state) => state.digitization.cancelDigiActivate)
    const panoramaCoordinate = useAppSelector(state => state.panorama.panoramaCoordinate);
    const isPanoMiniScreen = useAppSelector(state => state.panorama.isPanoMiniScreen);
    const services: any = useAppSelector(state => state.layer.services);
    const projects: Project[] = useAppSelector((state: RootState) => state.layer.projects);
    const panoramaType = useAppSelector(state => state.panorama.type);
    const isMultiOpen = useAppSelector(state => state.panorama.isMultiOpen);
    const wktGdhForParallel = useAppSelector(state => state.panorama.wktGdhForParallel);
    const [gdhForParallel, setGdhForParallel] = useState<any>()
    const language = useAppSelector((state) => state.language.value);
    const heightFieldMeasureActive: any = useAppSelector(state => state.buttonState.heightFieldMeasureActive)
    const cursorVisible: any = useAppSelector(state => state.panorama.cursorVisible)
    const from: string = useAppSelector(state => state.digitization.from)
    const panoByPanogps: boolean = useAppSelector(state => state.panorama.panoByPanogps)
    const layerNodes: any = useAppSelector(state => state.layer.layerNodes);
    const childKey:any = useAppSelector(state => state.panorama.childKey);
    const isMobileScreen: number = useAppSelector((state: any) => state.styleControl.isMobileScreen)
    
    /* const [rotationY, setRotationY] = useState<any>(undefined) */
    /* const [isFirstAnchor, setIsFirstAnchor] = useState<any>(true) */
    let isFirstAnchor = true;
    const otherStates: any = ['CLEAR', 'FINISH', 'DEFAULT', 'NONE'];
    const angle = useAppSelector((state) => state.panorama.angle)
    /*     const angle2 = useAppSelector((state) => state.panorama.angle2)
        const angle3 = useAppSelector((state) => state.panorama.angle3) */

    const tooltip = useRef<any>(null);
    const cloud = useRef<any>(null);
    const bright = useRef<any>(null);
    const exportCloud = useRef<any>(null);
    const calibration = useRef<any>(null);
    const [isPanoAvailable, setIsPanoAvailable] = useState<boolean>(true);
    const [prevPoint, setPrevPoint] = useState<{lat:number, lon:number}>({lat:0, lon:0});
    const [colorType, setColorType] = useState<number>(0);

    const [latLon, setLatLon] = useState<any>()
    const location = useLocation();
    let panogl: any
    let pano_2: any
    let pano_3: any
    let visibleLayerDict: any = {}
    const [roadProfileData, setRoadProfileData] = useState<any>([])
    const [cropData, setCropData] = useState<any>([])

    const [indexAr, setIndexAr] = useState<any>([])
    const [lastElement, setLastElement] = useState<any>()
    const [eventStatus, setEventStatus] = useState<any>()
    const allSelectedFeatures: any = useAppSelector(state => state.selectedFeature.feature)
    const goToSelection: any = useAppSelector((state) => state.table.goToSelection);
 
    const selectedKeysforPano = useAppSelector(state => state.layer.selectedKeysforPano);
    const visibleDomainObjs = useAppSelector(state => state.layer.visibleDomains);
    const isPanoScreen: number = useAppSelector((state: any) => state.styleControl.isPanoScreen)
    const panoDate = useRef("false");
    const increment = (el: any) => {
        el.preventDefault();
        if (el.deltaY < 0) {
            onSliderValueChange((+el.target.value + 0.1),
                el.target.name === Sliders.HEADING ? 0 :
                    el.target.name === Sliders.ROLL ? 1 :
                        el.target.name === Sliders.PITCH ? 2 : 3
            )
        } else {
            onSliderValueChange((+el.target.value - 0.1),
                el.target.name === Sliders.HEADING ? 0 :
                    el.target.name === Sliders.ROLL ? 1 :
                        el.target.name === Sliders.PITCH ? 2 : 3
            )
        }
    }
    (window as any).increment = increment
    const onFocusChanged = async (e: any, index: number) => {
        // if (lastElement) {
        // }
        e.target.addEventListener("wheel", increment)
        setLastElement(e.target)
    }

    let translated: any;
    let gdh_: any;
    const colorLdrSettings: any = [{
        label: 'Distance',
        value: 0
    },
    {
        label: 'ColorRGB',
        value: 1
    },
    {
        label: 'Intensity',
        value: 2
    }
    ]

    const [settings, setSettings] = useState<any>([{
        label: 'Heading',
        value: 0,
        minValue: -360,
        maxValue: 360
    },
    {
        label: 'Roll',
        value: 0,
        minValue: -90,
        maxValue: 90
    },
    {
        label: 'Pitch',
        value: 0,
        minValue: -90,
        maxValue: 90
    },
    {
        label: 'Camera Height',
        value: 0,
        minValue: 0,
        maxValue: 5
    }
    ])
    const [panoConfig, setPanoConfig] = useState<any>([{
        label: 'Heading',
        value: 0,
    },
    {
        label: 'Roll',
        value: 0,

    },
    {
        label: 'Pitch',
        value: 0,
    }
    ])
    let ankapanapiOptions: any

    useEffect(() => {
        if (updatedOlFeature && selectedGdhFeature && (buttonState === ButtonState.VERTEX || buttonState === ButtonState.MOVE)/* && from === "Panorama"*/) {
            // console.log("selectedGdhFeature :",selectedGdhFeature);
            const geoJson = new GeoJSON();

            let feature: any = geoJson.readFeature(updatedOlFeature);
            if (buttonState === ButtonState.VERTEX) {
                feature.getGeometry().transform('EPSG:4326', 'EPSG:3857')
            }
            feature.setId(selectedGdhFeature.attributes.__geomKey)
            // console.log("feature.getGeometry() :",feature.getGeometry());

            /*    if (feature.getGeometry().getType() === 'MultiPoint') {
                   let geom;
                   const wkt = new WKT();
                   const _geomClone: any = feature.getGeometry().clone()
                   geom = new MultiPoint([[_geomClone.transform('EPSG:3857', 'EPSG:4326').getCoordinates()]]);
                   const geomWktLine = wkt.writeGeometry(geom);
                   geom.transform('EPSG:4326', 'EPSG:3857');
                   const gdh: any = GeomDataHolder.fromWKT(geomWktLine);
                   (window as any).panogl.getScalable().getMainSketchLayer().addGlobal(gdh);
                   (window as any).panogl.getScalable().getMainSketchLayer().refresh();
               } else if (feature.getGeometry().getType() === 'MultiLineString') {
                   let geom;
                   const wkt = new WKT();
                   const _geomClone: any = feature.getGeometry().clone()
                   geom = new MultiLineString([_geomClone.transform('EPSG:3857', 'EPSG:4326').getCoordinates()]);
                   const geomWktLine = wkt.writeGeometry(geom);
                   geom.transform('EPSG:4326', 'EPSG:3857');
                   const gdh: any = GeomDataHolder.fromWKT(geomWktLine);
                   // (window as any).panogl.getScalable().getMainSketchLayer().addGlobal(gdh);
                   // (window as any).panogl.getScalable().getMainSketchLayer().refresh();
               } else if (feature.getGeometry().getType() === 'MultiPolygon') {
                   let geom;
                   const wkt = new WKT();
                   const _geomClone: any = feature.getGeometry().clone()
                   geom = new MultiPolygon(_geomClone.transform('EPSG:3857', 'EPSG:4326').getCoordinates());
                   const geomWktLine = wkt.writeGeometry(geom);
                   geom.transform('EPSG:4326', 'EPSG:3857');
                   const gdh: any = GeomDataHolder.fromWKT(geomWktLine);
                   (window as any).panogl.getScalable().getMainSketchLayer().addGlobal(gdh);
                   (window as any).panogl.getScalable().getMainSketchLayer().refresh();
               } */
            calculateResult(feature).then((calcFields: any) => {
                dispatch(setCalculatedFields(calcFields));

                // console.log("document.removeEventListener");

                document.removeEventListener('contextmenu', rightClickHandler)
            });
        }else if(buttonState === ButtonState.NONE && selectedGdhFeature){
            pano.getScalable().getMainSketchLayer().removeFromGlobal(selectedGdhFeature)
            pano.getScalable().getMainSketchLayer().refresh();
            dispatch(setDigiPopupVisibility(false))
        }

    }, [updatedOlFeature]);

    useEffect(() => {
        if (heightFieldMeasureActive) {

            pointCloudPlugin_?.startDrawing(DrawStatesPC.LINE_PC_DRAW);
            (window as any).currentDraw = DrawStateType.PANORAMA_TRIANGLE_PC
        } else {
            pointCloudPlugin_?.stopDraw();
            (window as any).currentDraw = '';
            (window as any).drawStateType = DrawStateType.NONE;
        }
    }, [heightFieldMeasureActive]);

    useEffect(() => {
        // console.log("services :",services);

        if (/*services.length && */panoByPanogps) {
            // let service_url = services.find((el:any) => el.name === 'panogps')?.url;
            try {
                // axios.get(service_url +
                // `/ows?service=WFS&version=1.0.0&srsname=EPSG:3857&maxFeatures=1&version=1.0.0&request=getfeature&token=${getToken()}&outputFormat=application/json&typeName=panogps`)
                // .then((response:any) => {
                //     // console.log("response",response);
                //     const translated:any = transform(response.data.features[0].geometry.coordinates, 'EPSG:3857', 'EPSG:4326');
                //     // console.log("translated :",translated);
                //     (window as any).panogl.gotoLocation(+translated[1], +translated[0]); 
                // })

                axios.get(`${REACT_APP_BASE_URL}rest/v1/pano/getAnyPanoGeom` +
                    `?token=${getToken()}`)
                    .then((response: any) => {
                        if (response?.data?.data) {
                            (window as any).panogl.gotoLocation(response?.data?.data?.coordy, response?.data?.data?.coordx);
                        }
                        // const translated:any = transform(response.data.features[0].geometry.coordinates, 'EPSG:3857', 'EPSG:4326');
                        // // console.log("translated :",translated);

                    })
            } catch (error) {
            }
        }
    }, [services]);


    useEffect(() => {
        ankapanapiOptions = {
            content: 'panodiv',
            aroundService: `${REACT_APP_BASE_URL}rest/v1/pano/around/`,
            imageService: `${REACT_APP_BASE_URL}pano/img/`,
            tileService: `${REACT_APP_BASE_URL}pano/tile/`,
            pointCloudURL: `${REACT_APP_BASE_URL}pano/`,
        };
        panogl = new PanoGL(ankapanapiOptions);
        (window as any).panogl = panogl // Düzeltilmeli
        panogl.setUrlFactory(Object.assign({}, AnkaPanAPI.PanoGL.URLFactory.prototype, {
            getRequestURL: (pano: any, lon: any, lat: any) => {
                return pano.jsonConenction + lon + '/' + lat + `/0/false` + `?token=${getToken()}`;
            }
        }));

        setPano(panogl);
        (window as any).pano = panogl;

        const softtext = new SoftTextPlugin();
        panogl.setPlugin(softtext);

        const pointCloudURL = ankapanapiOptions.pointCloudURL;
        const pointCloudPlugin = new PointCloudPlugin({ url: pointCloudURL, enablePoints: false });
        // setTimeout(() => {
        panogl.setPlugin(pointCloudPlugin);
        setPointCloudPlugin(pointCloudPlugin)
        // }, 2000);
        //nokta bulutunu panoda göstermek için

        const scalable = new ScalablePlugin();
        panogl.setPlugin(scalable);
        setScable(scalable)

        const draw_ = new DrawPlugin();
        if (draw_) {
            panogl.setPlugin(draw_);
            setDraw(draw_)
            panogl.getScalable().getMainSketchLayer().metric = localStorage.getItem("setMetricType") ? +(localStorage.getItem("setMetricType")!) : 3;
            panogl.setCompassVisibility(false);
    


            //panogl multimeasure -->  ng-packages pano-workspace\projects\ag-pano\src\lib\multimeasure\multimeasure.component.ts
            const multiMeasure = new MultiMeasurement;
            panogl.setPlugin(multiMeasure);
            setMultiMeasurePlugin(multiMeasure)

            var profilePlugin = new ProfilePlugin({ url: ankapanapiOptions.pointCloudURL })
            panogl.setPlugin(profilePlugin);
            setProfilePlugin(profilePlugin)

            //pano başlaması için default bir koordinata ihtiyacı var
            const username: any = localStorage.getItem('username')
            if (localStorage?.getItem(`lastCoordinate${username}`)) {
                const currentUrl = window.location.href;
                const coords = currentUrl.split('@Pan')[1]?.split('@Map')[0].split(',');
                if (coords && coords.length) {
                    panogl.gotoLocation(+coords[1], +coords[0]); // EPSG:4326 and lat,lon
                    dispatch(setPanoramaCoordinate({ lat: +coords[1], lon: +coords[0] }));
                } else {
                    const coordinates: any = localStorage?.getItem(`lastCoordinate${username}`)?.split(',')
                    // console.log("coordinates :",coordinates);
                    
                    panogl.gotoLocation(coordinates[1], coordinates[0]); // EPSG:4326 and lat,lon
                    dispatch(setPanoramaCoordinate({ lat: +coordinates[1], lon: +coordinates[0] }));
                }
                
            }
            else {
                dispatch(setPanoByPanogps(true));
                // panogl.gotoLocation(41.034225, 28.978966); // EPSG:4326 and lat,lon
            }
            panogl.start();
            panogl.addEvent(PanoGL.LOCATION_CHANGE, null, onLocationChange);
            panogl.dataParser.addEvent('dataLoaded', dataLoaded);
            panogl.addEvent(PanoGL.VIEW_ANGLE_CHANGE, null, onViewAngleChange);
            panogl.addEvent(PanoGL.MOUSE_MOVE_ON_GROUND, null, onCursorMoveGround);
            draw_.addEvent(AnkaDraw.DrawPlugin.STATUS_CHANGE, null, drawStatusChange);

            panogl.addEvent('onCursorMoveOnGround', null, onCursorMoveOnGround);

            pointCloudPlugin.addEvent(PointCloudPlugin.STATUS_CHANGE, null, drawStatusChange);

            panogl.setLabelVisibility(false);

            profilePlugin.addEvent(ProfilePlugin.LOADING_START, null, onProfileStatusChange);
            profilePlugin.addEvent(ProfilePlugin.LOADING_END, null, onProfileStatusChange);
            profilePlugin.addEvent(ProfilePlugin.COMPLETE, null, onProfileStatusChange);
            profilePlugin.addEvent(ProfilePlugin.END, null, onProfileStatusChange);


            //default heading pitch roll değeri
            getCameraConfig(panogl)

            panogl.setDirty()

            const interval = setInterval(() => {
                //mouse tekerleğinin yönünü değiştirir
                if (PanoromicController) {
                    PanoromicController.MouseWheelWay = -1;
                    clearInterval(interval);
                }
            }, 100);

            (window as any).selectedLayerNames = [];
            (window as any).selectedFeatureIds = [];
            (window as any).urls = [];
            (window as any).selectedFeatures = [];
        }

    }, [])

    useEffect(() => {
        if (allSelectedFeatures.length > 0 && goToSelection === true) {
            const extent = allSelectedFeatures[allSelectedFeatures.length - 1]?.geometry
            const geojson = new GeoJSON()

            if (extent) {
                let geom: any = geojson.readGeometry(extent)
                const point = [geom?.flatCoordinates[0], geom.flatCoordinates[1]]
                translated = transform(point, 'EPSG:3857', 'EPSG:4326')
                pano.gotoLocation(translated[1], translated[0])
                pano.addEvent(PanoGL.LOCATION_CHANGE, null, onLocationPanoFromTable)
                dispatch(setGoToSelection(false))
            }
            else {
                const search_extent = allSelectedFeatures[allSelectedFeatures.length - 1].values_?.geometry
                const point = [search_extent?.flatCoordinates[0], search_extent.flatCoordinates[1]]
                translated = transform(point, 'EPSG:3857', 'EPSG:4326')
                pano.gotoLocation(translated[1], translated[0])
                pano.addEvent(PanoGL.LOCATION_CHANGE, null, onLocationPanoFromTable)
                dispatch(setGoToSelection(false))
            }
        }
        return () => {
            setTimeout(() => {
                pano?.removeEvent(PanoGL.LOCATION_CHANGE, onLocationPanoFromTable)
            }, 500);
        }
    }, [allSelectedFeatures, goToSelection])

    useEffect(() => {
        if ((buttonState === ButtonState.VERTEX || buttonState === ButtonState.MOVE) && selectedGdhFeature) {
            pano?.addEvent(PanoGL.LOCATION_CHANGE, null, resetVertexState)
        }
        return () => {
            pano?.removeEvent(PanoGL.LOCATION_CHANGE, resetVertexState)
        }
    }, [selectedGdhFeature])

    const resetVertexState = () => {
        if (selectedGdhFeature) {
            if (buttonState === ButtonState.MOVE && uidNotFind(selectGdhFeature)) {
                pano.getScalable().getMainSketchLayer().removeFromGlobal(selectedGdhFeature)
                pano.getScalable().getMainSketchLayer().refresh();
            } else {
                selectedGdhFeature?.stopVertexEdit()
            }
            setSelectedGdhFeature(null)
        }
    }


    const onLocationPanoFromTable = (event: any) => {
        const panogl_ = event.currentTarget
        const data = panogl_.getCurrentPoint();
        let panoAngle = ((Math.atan2(translated[0] - data.lon, translated[1] - data.lat) * 180 / Math.PI) + 360) % 360;
        panogl_.getController().setRotationY(((360 - panoAngle) / 360) * 2 * Math.PI);
        if (isMultiOpen) {
            const prev = panogl_.getFrameLocation(-1);
            const next = panogl_.getFrameLocation(1);
            panoDiv2.gotoLocation(prev.lat, prev.lon);
            panoDiv3.gotoLocation(next.lat, next.lon);
        }
        /** gotopano select  */
        setTimeout(() => {
            const layers = pano.getScalable().getLayers();
            if (allSelectedFeatures.length) {
                for (const layer of layers) {
                    const geoms = layer._geometries;
                    for (const gdh of geoms) {
                        if (allSelectedFeatures.find((feature: any) => feature.id === gdh.attributes.__geomKey)) {
                            gdh.select()
                        } else {
                            gdh.deselect()

                        }
                    }
                    layer.redraw()
                }
            }
        }, 1000);
    }
    useEffect(() => {
        const el :any = document.getElementById('icons/measureComplete.svg');
        if (pano) {
            if (location.pathname && location.pathname !== '/') {
                const value = location.pathname?.split('@View')[1].split(',')
                if (value) {
                    pano.lookAtY(value[2]);
                }
            }
            if(el){
                // console.log("addEventListener");
                el.addEventListener("click", ffInterval/*, true*/)
            }
        }
        return ()=>{
            // console.log("removeEventListener");
            el?.removeEventListener("click", ffInterval);
        }
    }, [pano])

    useEffect(() => {
        if (view && pano) {
            setTimeout(() => {
                pano.getMainCamera().far = +view;
                pano.getController().isDirty = true;
            }, 100);
        }
    }, [view, pano]);

    useEffect(() => {
        const PanoGl = (window as any).AnkaPanAPI.PanoGLV2;
        const pano2Instances = AnkaPanAPI.PanoGL._instances.find((instance: any) => instance._content === 'panodiv2')

        if (pano && isMultiOpen && !pano2Instances) {
            //console.log(" pano2 ve pano3 BURADA ZATEN VAR");
            const pano2 = pano.createConnectedPano('panodiv2', ankapanapiOptions)
            const pano3 = pano.createConnectedPano('panodiv3', ankapanapiOptions)
            pano2.setUrlFactory(Object.assign({}, AnkaPanAPI.PanoGL.URLFactory.prototype, {
                getRequestURL: (pano: any, lon: any, lat: any) => {
                    return pano.jsonConenction + lon + '/' + lat + `/0/false` + `?token=${getToken()}`;
                }
            }));
            pano3.setUrlFactory(Object.assign({}, AnkaPanAPI.PanoGL.URLFactory.prototype, {
                getRequestURL: (pano: any, lon: any, lat: any) => {
                    return pano.jsonConenction + lon + '/' + lat + `/0/false` + `?token=${getToken()}`;
                }
            }));
            const prev = pano.getFrameLocation(-1);
            const next = pano.getFrameLocation(1);
            pano2.gotoLocation(prev.lat, prev.lon);
            pano3.gotoLocation(next.lat, next.lon);
            pano2.start()
            pano3.start()
            pano_2 = pano2
            pano_3 = pano3
            setPanoDiv2(pano2)
            setPanoDiv3(pano3)
            multiMeasurePlugin.addEvent(MultiMeasurement.STATUS_EVENT, null, multiDrawStatusChange);
            pano.addEvent(PanoGl.LOCATION_CHANGE, null, () => {
                const prev = pano.getFrameLocation(-1);
                const next = pano.getFrameLocation(1);
                pano2.gotoLocation(prev.lat, prev.lon);
                pano3.gotoLocation(next.lat, next.lon);
                dispatch(setCoordinateForPano2({ lat: prev.lat, lon: prev.lon }))
                dispatch(setCoordinateForPano3({ lat: next.lat, lon: next.lon }))
            });

            if (pano2) {
                pano2.setCompassVisibility(false)
                pano2.setLabelVisibility(false)

                pano2.addEvent(PanoGl.LOCATION_CHANGE, null, function (e: any) {
                    dispatch(setCoordinateForPano2({ lat: e.lat, lon: e.lon }))
                });
                pano2.addEvent(PanoGl.VIEW_ANGLE_CHANGE, null, function (e: any) {
                    const fov = pano2.controller.getFov()
                    dispatch(setViewAngle2({ rotationX: e.rotationX, rotationY: e.rotationY, fov: fov }))
                });
            }

            if (pano3) {
                pano3.setCompassVisibility(false)
                pano3.setLabelVisibility(false)

                pano3.addEvent(PanoGl.LOCATION_CHANGE, null, function (e: any) {
                    dispatch(setCoordinateForPano3({ lat: e.lat, lon: e.lon }))
                });
                pano3.addEvent(PanoGl.VIEW_ANGLE_CHANGE, null, function (e: any) {
                    const fov = pano3.controller.getFov()
                    dispatch(setViewAngle3({ rotationX: e.rotationX, rotationY: e.rotationY, fov: fov }))
                });
            }
        }
    }, [isMultiOpen])

    useEffect(() => {
        if (isMultiOpen && panoDiv2) {
            const panoBrightness = pano.getBrightness()
            panoDiv2.setBrightness(panoBrightness)
            panoDiv3.setBrightness(panoBrightness)
        }
    }, [isMultiOpen, brightness])

    const onProfileStatusChange = (event: any) => {
        if (event.type === 'onLoadingEnd' && event.currentTarget._profileGeoms && event.currentTarget._profileGeoms.length === 0) {
            toast.current?.show({ severity: 'info', detail: t('MESSAGE.Data not found'), life: 3000 });
        }
        if (event.type === 'complete') {
            setChartVisibility(true)
            setCropData(event.cropData)
        }
    }
    const ffInterval:any=(e:any)=> {
        if(!videoStart){
            // (window as any).startVideo = true;
            console.log("videoSpeedValue :",videoSpeedValue);

            pano?.getTilePlugin().resetForPlay()
            
            // const myInterval_ = setInterval(() => {
            //     goToLocationByFrame(1)
            //     // pano.gotoNextFrame() 
            // }, videoSpeedValue);
            // // (window as any).myInterval = myInterval_
            // setMyInterval(myInterval_)
        }else{
            // if((window as any).startVideo){
                
            
            // clearInterval(myInterval);
            pano?.getTilePlugin().stopPlaying()



                // clearInterval((window as any).myInterval);
                // (window as any).startVideo = false;
            // }else{
              /*   (window as any).startVideo = true;
                const myInterval = setInterval(() => {
                    goToLocationByFrame(1)
                    // pano.gotoNextFrame() 
                }, 500);
                (window as any).myInterval = myInterval */
            // }
        }
        // if(!(window as any).startVideo){
        //     (window as any).startVideo = true;
        //     const myInterval = setInterval(() => {
        //         goToLocationByFrame(1)
        //         // pano.gotoNextFrame() 
        //     }, 500);
        //     (window as any).myInterval = myInterval
        // }else{
        //     if((window as any).startVideo){
        //         clearInterval((window as any).myInterval);
        //         (window as any).startVideo = false;
        //     }else{
        //       /*   (window as any).startVideo = true;
        //         const myInterval = setInterval(() => {
        //             goToLocationByFrame(1)
        //             // pano.gotoNextFrame() 
        //         }, 500);
        //         (window as any).myInterval = myInterval */
        //     }
        // }
        // setInterval(displayHello, 1000);
     /*    if((window as any).drawStateType === DrawStateType.FINISH){
            if((window as any).myInterval){
                clearInterval((window as any).myInterval)
            }
        }else{
            const myInterval = setInterval(() => {
                goToLocationByFrame(1)
                // pano.gotoNextFrame() 
            }, 500);
            (window as any).myInterval = myInterval
        } */

        // return myInterval;
        /* const panodiv_:any=document.getElementById('panodiv');
        let event = new MouseEvent("contextmenu", {
            bubbles: true,
            cancelable: true,
            clientX: 100,
            clientY: 100
          });
          //new CustomEvent('contextmenu',{bubbles: true}) //new Event("contextmenu");
        panodiv_.dispatchEvent(event); */
    };
    useEffect(() => {
        const el :any = document.getElementById('icons/complete.svg');
        const panodiv_:any = document.getElementById('panodiv');

        if (pano) {
            if (pano.mainPanoSphere._lastPath){
                let patharray = pano.mainPanoSphere._lastPath.split("/") ?? ""
                const dirname = patharray[patharray.length - 4]
                if (dirname !== lastDirname)
                {
                    getCameraConfig(pano)
                }
                setLastDirname(dirname)
                }
            
       
            // console.log("el: ", el);
            
            if(el){
                el.addEventListener("click", ()=>{

                    setTimeout(() => {
                        const panodiv_:any=document.getElementById('panodiv');
                    //  console.log("panodiv_ :",panodiv_);
                    
                    let event = new MouseEvent("contextmenu", {
                        bubbles: true,
                        cancelable: true,
                        clientX: 100,
                        clientY: 100
                    });
                    // console.log("event :",event);
                    
                    //new CustomEvent('contextmenu',{bubbles: true}) //new Event("contextmenu");
        
                    panodiv_.dispatchEvent(event);
                    }, 300);
                    

                }/*, true*/)
             /*    if(!(window as any).ffInterval){
                    (window as any).ffInterval = true;
                } */
            }       
        }
        return ()=>{
            // el?.removeEventListener("click", ffInterval);
        }
    }, [panoramaCoordinate])

    const getCameraConfig = async (panogl: any) => {
        axios.get(`${panogl._aroundService}${panoramaCoordinate.lon}/${panoramaCoordinate.lat}/?token=${getToken()}`).then((response: any) => {
            const panoConfig = response.data.config;
            //console.log("panoConfig :",panoConfig);
           setPanoConfig([{
                label: 'Heading',
                value: panoConfig.heading
            },
            {
                label: 'Roll',
                value: panoConfig.roll
            },
            {
                label: 'Pitch',
                value: panoConfig.pitch
            }
            ])
            setSettings([{
                label: 'Heading',
                value: panoConfig.heading,
                minValue: -360,
                maxValue: 360
            },
            {
                label: 'Roll',
                value: panoConfig.roll,
                minValue: -90,
                maxValue: 90
            },
            {
                label: 'Pitch',
                value: panoConfig.pitch,
                minValue: -90,
                maxValue: 90
            },
            {
                label: 'Camera Height',
                value: panoConfig.camheight,
                minValue: 0,
                maxValue: 5
            }
            ])  /* */
            setIsPanoAvailable(true);
        }).catch((err: any) => {

            if (err.response && err.response.data && err.response.data.message) {

                // If there is no last coordinate here and there is an error message, it prints the error. 
                // If there is a last coordinate and it matches the first coordinate, an error is given according to the error situation in the first around request.
                //// *SHOULD BE FIXED* If the second last coordinate is wrong, it doesn't fall here anyway, so you need to check it. ()
                //// *SHOULD BE FIXED* This is currently in the comment line because if any action is to be taken on the latter, the error state of the latter must also be previewed.
                // if (localStorage.getItem('lastCoordinate')) {
                //     const coordinates: any = localStorage.getItem('lastCoordinate')?.split(',');
                //     const isLastCoordinate = parseFloat(coordinates[1]) === panoramaCoordinate.lat && parseFloat(coordinates[0]) === panoramaCoordinate.lon ? true : false
                //     if (isLastCoordinate) handleErrorMessage(err.response.data.message);
                //     handleErrorMessage(err.response.data.message);
                // } else {
                //     handleErrorMessage(err.response.data.message)
                // }
                console.log("(window as any).innerHeight :",(window as any).innerHeight);
                
                if((window as any).innerHeight>=680){
                    handleErrorMessage(err.response.data.message)
                }
            } else {
                // console.error(err);
            }
        })
    }

    useEffect(() => {
        //  const btnState :string = buttonState;
        (window as any).selectedLayer = selectedLayer;
        if (buttonState === ButtonState.ADD) {

            dispatch(setButtonState(ButtonState.NONE))

            setTimeout(() => {
                dispatch(setButtonState(buttonState))
            }, 200);
        }
    }, [selectedLayer]);

    useEffect(() => {
        // dispatch(setClear(true))

        // setDigiOlFeature(null)
        // setDrawEnd(false)
        // setFeatureCoordinates(null)

        (window as any).drawStateType = drawState.drawStateType;
        // console.log("(window as any).drawStateType : ",(window as any).drawStateType);
        // draw?.stopDrawingForAll();
        if (drawState.drawStateType === DrawStateType.NONE || buttonState === ButtonState.NONE) {
            // ????? digiyi sonlandırır draw?.stopDrawingForAll();
        /*     if(panoramaType==="Floor"){
                draw?.stopDrawingForAll()
            } else if(panoramaType==="Point Cloud"){
          //      pointCloudPlugin_?.stopDraw()
            } else if(panoramaType==="Multi Display"){
                multiMeasurePlugin?.stopMultiMeasureInAllInstances();
            }
            ??
            
            */
            selectedGdhFeature?.stopVertexEdit()
            selectedGdhFeature?.stopMoveEdit()
            pointCloudPlugin_?.stopDraw();

            // setDigiOlFeature(null)

            /* if (buttonState !== ButtonState.ADD && buttonState !== ButtonState.EDIT && buttonState !== ButtonState.VERTEX &&
                buttonState !== ButtonState.SPLIT && buttonState !== ButtonState.PARALLEL && buttonState !== ButtonState.MOVE) {
                draw?.stopDrawingForAll()
            } */
        }
        if (drawState.drawStateType !== DrawStateType.NONE && drawState.drawStateType !== DrawStateType.CLEAR) {
            if (panoramaType === "Point Cloud") {
                toast.current?.show({ severity: 'info', detail: (t('BUTTON.THISDRAWJUSTFORPANORAMA')), life: 3000 });
            }

            /*       if(digiPopupVisibility && buttonState === ButtonState.ADD){
                      console.log("z O N K  3333333333333333333333",true);
                      (window as any).drawWithDigi = true;
                  } */

            //aşağıdaki kod "fullscreen" yaparken buton state'ini none'a çektiği için ve buton aktif görüntüsü
            //kaybolduğu için yoruma alındı. başka bir şey için kullanılması gerekiyorsa buna göre müdahale edilsin lütfen...
            // dispatch(setButtonState(ButtonState.NONE))
        }


        // if(drawState.drawStateType !== DrawStateType.NONE){
        //     draw?.stopDrawingForAll()
        // }

        if (buttonState !== ButtonState.ADD || (window as any).drawWithDigi) {
            /**
             * Measure
             */
            setTimeout(() => {
                (window as any).currentDraw = drawState.drawStateType;
                if (drawState.drawStateType === DrawStateType.POLYGON) {
                    draw?.startPolygon();
                }
                else if (drawState.drawStateType === DrawStateType.LINESTRING) {
                    draw?.startLine();
                }
                else if (drawState.drawStateType === DrawStateType.POINT) {
                    draw?.startPoint();
                }
                else if (drawState.drawStateType === DrawStateType.HLINE) {
                    if (panoramaType === "Floor") {
                        draw?.startHLine();
                        toast.current?.show({ severity: 'info', detail: (t('BUTTON.THISDRAWJUSTFORPANORAMA')), life: 3000 });
                    } else if (panoramaType === "Point Cloud") {
                        pointCloudPlugin_?.startDrawing(DrawStatesPC.GROUND_FROM_TOP_PC_DRAW);
                    }
                }
                else if (drawState.drawStateType === DrawStateType.ROAD_LINE) {
                    draw?.startRoadLine();
                    if (panoramaType === "Floor") {
                        toast.current?.show({ severity: 'info', detail: (t('BUTTON.THISDRAWJUSTFORPANORAMA')), life: 3000 });
                    }
                }
                else if (drawState.drawStateType === DrawStateType.GRID) {
                    draw?.startGridLine();
                    if (panoramaType === "Floor") {
                        toast.current?.show({ severity: 'info', detail: (t('BUTTON.THISDRAWJUSTFORPANORAMA')), life: 3000 });
                    }
                }
                else if (drawState.drawStateType === DrawStateType.PANORAMA_POİNT_PC) {
                    pointCloudPlugin_?.startDrawing(DrawStatesPC.POINT_PC_DRAW);
                    (window as any).currentDraw = DrawStateType.PANORAMA_POİNT_PC
                }
                else if (drawState.drawStateType === DrawStateType.PANORAMA_LINE_PC) {
                    // console.log("LINE_PC_DRAW  LINE_PC_DRAW");
                    // setTimeout(() => {
                    pointCloudPlugin_?.startDrawing(DrawStatesPC.LINE_PC_DRAW);
                    (window as any).currentDraw = DrawStateType.PANORAMA_LINE_PC
                    // }, 100);
                }
                else if (drawState.drawStateType === DrawStateType.PANORAMA_POLYGON_PC) {
                    pointCloudPlugin_?.startDrawing(DrawStatesPC.POLYGON_PC_DRAW);
                    (window as any).currentDraw = DrawStateType.PANORAMA_POLYGON_PC
                }
                else if (drawState.drawStateType === DrawStateType.PANORAMA_TRIANGLE_PC) {
                    pointCloudPlugin_?.startDrawing(DrawStatesPC.LINE_PC_DRAW);
                    (window as any).currentDraw = DrawStateType.PANORAMA_TRIANGLE_PC
                }
                else if (drawState.drawStateType === DrawStateType.PANORAMA_SQUARE_PC) {
                    pointCloudPlugin_?.startDrawing(DrawStatesPC.SQUARE_PC_DRAW);
                    (window as any).currentDraw = DrawStateType.PANORAMA_SQUARE_PC
                }
                else if (drawState.drawStateType === MultiDrawTypes.POINT_MULTI) {
                    multiMeasurePlugin.startMultiMeasure(null, 'POINT_MULTI');
                    (window as any).currentDraw = MultiDrawTypes.POINT_MULTI
                }
                else if (drawState.drawStateType === MultiDrawTypes.LINE_MULTI) {
                    multiMeasurePlugin.startMultiMeasure(null, 'DEFAULT');
                    (window as any).currentDraw = MultiDrawTypes.LINE_MULTI
                }
                else if (drawState.drawStateType === MultiDrawTypes.POLYGON_MULTI) {
                    multiMeasurePlugin.startMultiMeasure(null, 'POLYGON');
                    (window as any).currentDraw = MultiDrawTypes.POLYGON_MULTI
                }
                else if (drawState.drawStateType === MultiDrawTypes.HLINE_MULTI) {
                    multiMeasurePlugin.startMultiMeasure(null, 'HLINE');
                    (window as any).currentDraw = MultiDrawTypes.HLINE_MULTI
                }
                else if (drawState.drawStateType === MultiDrawTypes.SQUARE_MULTI) {
                    multiMeasurePlugin.startMultiMeasure(null, 'SQUARE');
                    (window as any).currentDraw = MultiDrawTypes.SQUARE_MULTI
                }
                else if (drawState.drawStateType === MultiDrawTypes.SQUARE_MULTI) {
                    multiMeasurePlugin.startMultiMeasure(null, 'SQUARE');
                    (window as any).currentDraw = MultiDrawTypes.SQUARE_MULTI
                }
                else if (drawState.drawStateType === MultiDrawTypes.DIGI_POINT_MULTI) {
                    multiMeasurePlugin.startMultiMeasure(null, 'POINT');
                    (window as any).currentDraw = MultiDrawTypes.DIGI_POINT_MULTI
                }
                else if (drawState.drawStateType === MultiDrawTypes.DIGI_LINE_MULTI) {
                    multiMeasurePlugin.startMultiMeasure(null, 'DEFAULT');
                    (window as any).currentDraw = MultiDrawTypes.DIGI_LINE_MULTI
                }
                else if (drawState.drawStateType === MultiDrawTypes.DIGI_POLYGON_MULTI) {
                    multiMeasurePlugin.startMultiMeasure(null, 'POLYGON');
                    (window as any).currentDraw = MultiDrawTypes.DIGI_POLYGON_MULTI
                }
                else if (drawState.drawStateType === DrawStateType.CLEAR) {
                    // console.log("clearAll terminate");
                    // dispatch(setDrawUids([]))
                    /*                const uids : any = (window as any).drawUids ? (window as any).drawUids : [];
                                   const st = pano.getScalable().getMainSketchLayer().getGeomListClone()//[pano.getScalable().getMainSketchLayer().getGeomListClone().length-1]
                                   st.forEach((stt:any) => {
                                       if(uids.find((el:any)=> el === stt.uid)){
                                           console.log("removeFromGlobal:",stt);
                                           pano.getScalable().getMainSketchLayer().removeFromGlobal(stt)
                                           // pano.getScalable().getMainSketchLayer().removeFromGlobal(selectedGdhFeature)
                                           pano.getScalable().getMainSketchLayer().refresh();
                                       }
                                   });
                                   (window as any).drawUids = [];*/
                    pano.getScalable()?.getMainSketchLayer().clearAll();
                    /** Kaldırıldı. */
                    // dispatch(setDrawState({drawStateType: DrawStateType.NONE}))
                }
            }, 200);
        }
    }, [drawState, buttonState])

    useEffect(() => {
        // console.log("pointCloudPlugin_?.stopDraw();",panoramaType);

        // draw?.stopDrawingForAll();
        // pointCloudPlugin_?.stopDraw();
        // multiMeasurePlugin?.stopMultiMeasureInAllInstances();

        // dispatch(setDrawState({ drawStateType: DrawStateType.NONE }))
        // setTimeout(() => {
        dispatch(setButtonState(ButtonState.NONE))
        dispatch(setDrawState({ drawStateType: DrawStateType.NONE }))
        // }, 100);
    }, [/*selectedLayer, */panoramaType]);

    useEffect(() => {
        if ((window as any).panoramaType === 'Floor') {
            draw?.stopDrawingForAll();
        } else if ((window as any).panoramaType === 'Point Cloud') {
            pointCloudPlugin_?.stopDraw();
        } else if ((window as any).panoramaType === 'Multi Display') {
            multiMeasurePlugin?.stopMultiMeasureInAllInstances();
        }
    }, [panoramaType]);


    useEffect(() => {
        if (pano && clickedMapCoordinate) {
            const translated = transform(clickedMapCoordinate, 'EPSG:3857', 'EPSG:4326')
            pano.gotoLocation(translated[1], translated[0])
        }
    }, [clickedMapCoordinate])

    useEffect(() => {
        if (mapCoordinates.type) {
            const wkt = new WKT();
            const openlayersFeature = wkt.readFeature(mapCoordinates.feature);
            openlayersFeature.getGeometry()?.transform('EPSG:3857', 'EPSG:4326')
            const wktFeature = wkt.writeFeature(openlayersFeature)
            const gdh = GeomDataHolder.fromWKT(wktFeature);
            pano.getScalable().getMainSketchLayer().addGlobal(gdh);
            pano.getScalable().getMainSketchLayer().refresh();
        }
    }, [mapCoordinates])

    useEffect(() => {
        if (coordinate) {
            const coordinateTransform = proj4('EPSG:4326', projection, coordinate);
            const fixed = projection === 'EPSG:4326' ? [+coordinateTransform[0].toFixed(6), +coordinateTransform[1].toFixed(6)] : [+coordinateTransform[0].toFixed(4), +coordinateTransform[1].toFixed(4)]
            dispatch(setPointerCoordinate(fixed));
        }
    }, [projection, coordinate])

    //tabloda feature seçildiği anda panorama hareket ediyor.bu yüzden yoruma alındı.
    // useEffect(() => {
    //     if (selectedFeature && selectedFeature.length !== 0 && selectedFeature[0].type &&
    //         !infoButtonState &&
    //         buttonState !== ButtonState.EDIT &&
    //         buttonState !== ButtonState.DELETE &&
    //         buttonState !== ButtonState.VERTEX) {
    //         const geoJson = new GeoJSON();
    //         let feature = geoJson.readFeature(selectedFeature[0]);
    //         const transformed: any = feature.getGeometry()?.transform('EPSG:3857', 'EPSG:4326')
    //         if (transformed && pano) {
    //             pano.gotoLocation(transformed.flatCoordinates[1], transformed.flatCoordinates[0])
    //         }
    //     }
    // }, [selectedFeature])

    useEffect(() => {
        if (buttonState === ButtonState.ROAD_PROFILE && pano && profilePlugin) {
            profilePlugin.start();
        }
        if (buttonState !== ButtonState.ROAD_PROFILE && pano) {
            clearProfilePlugin()
        }
        return () => {
            if (pano && profilePlugin) {
                clearProfilePlugin()
            }
        }
    }, [buttonState, profilePlugin])

    const clearProfilePlugin = () => {
        profilePlugin.removeEvent(ProfilePlugin.COMPLETE, onProfileStatusChange);
        profilePlugin.removeEvent(ProfilePlugin.END, onProfileStatusChange);
        profilePlugin.stop();
        profilePlugin.clear();
        setChartVisibility(false)
    }

    //burada panorama üzerinde feature info alınmaktadır.
    useEffect(() => {
        if (pano) {
            if (buttonState === ButtonState.INFO || buttonState === ButtonState.SELECTION || buttonState === ButtonState.EDIT || buttonState === ButtonState.VERTEX || buttonState === ButtonState.DELETE
                || buttonState === ButtonState.MOVE) {
                const layers = pano.getScalable().getLayers();
                layers?.find((layer: any) => {
                    layer.setMouseEnable(true)
                    layer.addEvent(AnkaScalable.GeoServerWFSLayer.FEATURE_CLICK, null, selectGdhFeature)
                })
            } /*else {
                const layers = pano.getScalable().getLayers();
                layers?.find((layer: any) => layer.setMouseEnable(false))
            }*/

            // if (buttonState === ButtonState.NONE) {
            //     dispatch(setSelectedFeature([]))
            // }
        }
        return () => {
            if (pano) {
                const layers = pano.getScalable().getLayers();
                layers?.find((layer: any) => {
                    layer.setMouseEnable(false)
                    layer.removeEvent(AnkaScalable.GeoServerWFSLayer.FEATURE_CLICK, selectGdhFeature)
                })
            }
        }
    }, [pano, buttonState, selectedLayer])

    useEffect(() => {
        if (wktGdhForParallel) {
            if (gdhForParallel && uidNotFind(gdhForParallel)) {
                pano.getScalable().getMainSketchLayer().removeFromGlobal(gdhForParallel)
            }
            let gdh_ = GeomDataHolder.fromWKT(wktGdhForParallel)
            pano.getScalable().getMainSketchLayer().addGlobal(gdh_)
            pano.getScalable().getMainSketchLayer().refresh()
            setGdhForParallel(gdh_)
        } else {
            if (gdhForParallel && uidNotFind(gdhForParallel)) {
                //removeFromGlobal
                // console.log("gdhForParallel  : ",gdhForParallel);
                pano.getScalable().getMainSketchLayer().removeFromGlobal(gdhForParallel)
                pano.getScalable().getMainSketchLayer().refresh()
                // pano.getScalable().getMainSketchLayer().clearAll()
            }
        }
    }, [wktGdhForParallel])

    const selectGdhFeature = (e: any) => {
        const selectedLayerNames: any[] = (window as any).selectedLayerNames;
        const selectedFeatureIds: any[] = (window as any).selectedFeatureIds;
        const selectedFeatures: any[] = (window as any).selectedFeatures;
        layerNodes?.map((node: any) => {
            if (node.children) {
              node.children.map((child: any) => {
                child.children.find((child2: any) => {
                  if (child2.name === e.gdh.attributes.__geomKey.split('.')[0]) {
                    if (child2) {
                      const findLayerType = services.find((service: any) => service.id === child2.service_id).type
                      const url = services.find((service: any) => service.id === child2.service_id)?.url
                      const workspace = services.find((service: any) => service.id === child2.service_id).workspace;
                      const service = services.find((service: any) => service.id === child2.service_id)
                      const layerPerm = service?.layers.find((layer: any) => child2.id === layer.id)?.perm
                      //  child2.perm = layerPerm
                      const cloneObject = { ...child2 }//JSON.parse(JSON.stringify(child2));
                      cloneObject.perm = layerPerm
      
                      if (findLayerType !== 'EXTERNAL_LAYER' && findLayerType !== 'RASTER') {
                        dispatch(setSelectedLayerGeoserverUrl(url));
                        dispatch(setWorkspace(workspace));
                        dispatch(setSelectedLayer(cloneObject))
                      } else return
                    }
                  }
                })
              })
            }
          }) 
        
        if (((buttonState === ButtonState.VERTEX || buttonState === ButtonState.DELETE || buttonState === ButtonState.EDIT || buttonState === ButtonState.MOVE) && (selectedLayer?.name && selectedLayer?.name === e.gdh.attributes.__geomKey.split('.')[0]))) {
            // dispatch(setSelectedFeature([]))
            setSelectedGdhFeature(e.gdh)
            dispatch(setPanoramaEditStatus(true))
        }
        if ((buttonState === ButtonState.VERTEX || buttonState === ButtonState.DELETE || buttonState === ButtonState.EDIT || buttonState === ButtonState.MOVE) && selectedLayer?.name !== e.gdh.attributes.__geomKey.split('.')[0]) {
            // showMessage(t('TEXT.Continue Your Process On The Selected Layer!'))
            // console.log("Continue Your Process On The Selected Layer!",e.gdh.attributes.__geomKey);
            layerNodes?.map((node: any) => {
                if (node.children) {
                    node.children.map((child: any) => {
                        child.children.find((child2: any) => {
                            if (child2.name === e.gdh.attributes.__geomKey.split('.')[0]) {
                                if (child2) {
                                    const findLayerType = services.find((service: any) => service.id === child2.service_id).type
                                    const url = services.find((service: any) => service.id === child2.service_id)?.url
                                    const workspace = services.find((service: any) => service.id === child2.service_id).workspace;
                                    const service = services.find((service: any) => service.id === child2.service_id)
                                    const layerPerm = service?.layers.find((layer: any) => child2.id === layer.id)?.perm
                                    //  child2.perm = layerPerm
                                    const cloneObject = { ...child2 }//JSON.parse(JSON.stringify(child2));
                                    cloneObject.perm = layerPerm

                                    if (findLayerType !== 'EXTERNAL_LAYER' && findLayerType !== 'RASTER') {
                                        dispatch(setSelectedLayerGeoserverUrl(url));
                                        dispatch(setWorkspace(workspace));
                                        dispatch(setSelectedLayer(cloneObject))

                                        // dispatch(setSelectedFeature([selectedFeature]))
                                        setSelectedGdhFeature(e.gdh)
                                        //    selectedGdhFeature.editVertex();
                                     //   dispatch(setPanoramaEditStatus(false))

                                        // dispatch(setPanoramaEditStatus(false))
                                    } else return
                                }
                            }
                        })
                    })
                }
            })
            /*  selectedGdhFeature?.stopVertexEdit()
             setUpdatedOlFeature(null);
             dispatch(setSelectedFeature([]))
             dispatch(setPanoramaEditStatus(false)) */
        }
        else {
            dispatch(setPanoramaEditStatus(false))

            // if (selectedGdhFeature) {
            //     console.log("selectedGdhFeature :",selectedGdhFeature);

            //     selectedGdhFeature.stopVertexEdit();
            //     const gdhToGeoJson = selectedGdhFeature.toGeoJSON();
            //     delete gdhToGeoJson.ankaProperties
            //     delete gdhToGeoJson.properties.srid
            //     delete gdhToGeoJson.properties.bbox
            //     const geoJson = new GeoJSON();
            //     let feature: any = geoJson.readFeature(gdhToGeoJson);
            //     feature.getGeometry().transform('EPSG:4326', 'EPSG:3857')
            //     feature.setId(selectedGdhFeature.attributes.__geomKey)
            //     //update için geojson halini gönderiyorum
            //     setUpdatedOlFeature(gdhToGeoJson)
            //     setUpdatedFeatureType(feature.getGeometry().getType())
            //     setUpdatedFeatureCoordinates(feature.getGeometry().getCoordinates())
            // }
        }

        /*
        const selectedLayerName = e.gdh.attributes.__geomKey.split('.')[0];
        const selectedFeatureId = e.gdh.attributes.__geomKey

        let url;
        projects.map((project: Project) => {
            project.layer_groups.map((layerGroup: any) => {
                const selectedLayer = layerGroup.layers.find((layer: any) => layer.name === selectedLayerName);
                if (selectedLayer) {
                    url = services.find((service: any) => service.id === selectedLayer.service_id)?.url

                }
            })

        })

        if (selectedLayerName && url) {
            FeatureRestApi.getFeatures(url, selectedLayerName).then((res: any) => {
                if (res.status === 200 && res.data.features.length > 0) {
                    const findedFeature = res.data.features.find((features: any) => features.id === selectedFeatureId)
                    dispatch(setSelectedFeature([findedFeature]));
                }
            })
        }
        */
        const layers = e.target.panogl.getScalable().getLayers()
        if (buttonState !== ButtonState.SELECTION) {
            const layers = e.target.panogl.getScalable().getLayers()
            selectedLayerNames.splice(0, selectedLayerNames.length)
            selectedFeatureIds.splice(0, selectedFeatureIds.length)
            selectedFeatures.splice(0, selectedFeatures.length)
            for (const layer of layers) {
                const geoms = layer._geometries;
                for (const gdh of geoms) {
                    if (gdh.isSelected()) {
                        gdh.deselect()
                    }
                }
                layer.redraw()
            }
        }
        if (buttonState === ButtonState.SELECTION || buttonState === ButtonState.INFO || buttonState === ButtonState.EDIT || buttonState === ButtonState.DELETE
            || buttonState === ButtonState.MOVE || buttonState === ButtonState.VERTEX) {
            const ctrlDown: any = (window as any).ctrlDown
            if (!ctrlDown) {
                for (const layer of layers) {
                    const geoms = layer._geometries;
                    for (const gdh of geoms) {
                        if (gdh.isSelected()) {
                            gdh.deselect()
                        }
                    }
                }
                selectedLayerNames.splice(0, selectedLayerNames.length)
                selectedFeatureIds.splice(0, selectedFeatureIds.length)
                selectedFeatures.splice(0, selectedFeatures.length)
            }
            // setSelectedGdhFeature(e.gdh)
            e.gdh.select()
            e.layer.redraw()

            selectedLayerNames.push(e.gdh.attributes.__geomKey.split('.')[0])
            selectedFeatureIds.push(e.gdh.attributes.__geomKey)
        }
        projects.map((project: Project) => {
            project.layer_groups.map((layerGroup: any) => {
                for (let j = 0; j < selectedLayerNames.length; j++) {
                    const selectedLayer = layerGroup.layers.find((layer: any) => layer.name === selectedLayerNames[j]);
                    if (selectedLayer) {
                        let url: any = services.find((service: any) => service.id === selectedLayer.service_id)?.url
                        FeatureRestApi.getFeatures(url, selectedLayerNames[j] + `&FEATUREID=${selectedFeatureIds[0]}}`).then((res: any) => {
                            // console.log("url:",url);

                            if (res.status === 200 && res.data.features.length > 0) {
                                const findedFeature = res.data.features.find((features: any) => features.id === selectedFeatureIds[j])
                                if (!selectedFeatures.find((el: any) => el.id === findedFeature.id)) {
                                    selectedFeatures.push(findedFeature);
                                }
                                if (/*buttonState === ButtonState.SELECTION*/buttonState !== ButtonState.VERTEX) {
                                    dispatch(setSelectedFeature([...selectedFeatures]))
                                    dispatch(setVertexFeature([]))
                                }
                                //
                                // Tekrar bakılcak.

                                // else if (/*buttonState === ButtonState.SELECTION*/buttonState === ButtonState.VERTEX) {

                                //     setSelectedGdhFeature(null)
                                //     dispatch(setVertexFeature([...selectedFeatures]))
                                //     dispatch(setSelectedFeature([]))
                                //     setSelectedGdhFeature(e.gdh)
                                //     console.log("e.gdh :",e.gdh);
                                //     pano.getScalable().getMainSketchLayer().removeFromGlobal(e.gdh)
                                //     pano.getScalable().getMainSketchLayer().refresh();
                                //     //  pano.getScalable()?.getMainSketchLayer().clearAll();
                                // }
                            }
                        })
                    }
                }
            })
        })
        // if (buttonState === ButtonState.NONE) {
        //     dispatch(setSelectedFeature([]))
        // }
        if (buttonState === ButtonState.DELETE) {
            setSelectedGdhFeature(e.gdh)
        }
    }
    const canceled = () => {
        // console.log("canceled",selectedGdhFeature);

        /*     pano.getScalable().getMainSketchLayer().removeFromGlobal(selectedGdhFeature)
        //    pano.getScalable()?.getMainSketchLayer().clearAll();
            pano.getScalable().getMainSketchLayer().refresh(); */
        setTimeout(() => {
            setSelectedGdhFeature(null);
            setUpdatedOlFeature(null);
        }, 100);

    }
    const gdhToTransaction = async (selectedGdh: any, id: any) => {
        if (Array.isArray(selectedGdh.points[0])) {
            for (const point of selectedGdh.points[0]) {
                if (isNaN(point.alt)) {
                    point.alt = -5000
                }
            }
        }
        const geoJson = selectedGdh.toGeoJSON();
        geoJson.properties.id = selectedGdhFeature.attributes.id
        geoJson.id = selectedGdhFeature.attributes.__geomKey
        delete geoJson.ankaProperties
        delete geoJson.properties.srid
        delete geoJson.properties.bbox
        if (geoJson.geometry.type === 'Line') {
            geoJson.geometry.type = 'LineString'
        }
        if (geoJson.geometry.type === 'Point') {
            geoJson.geometry.type = 'MultiPoint'
            geoJson.geometry.coordinates = [geoJson.geometry.coordinates]
        }
        const olGeoJson = new GeoJSON()
        const feature: any = olGeoJson.readFeature(geoJson)
        feature.getGeometry().transform('EPSG:4326', 'EPSG:3857')
        const geoJsonFinal = olGeoJson.writeFeatureObject(feature)
        feature.setId(selectedGdh.attributes.__geomKey)
        //update için geojson halini gönderiyorum
        setUpdatedOlFeature(geoJsonFinal)
        setUpdatedFeatureType(feature.getGeometry().getType())
        setUpdatedFeatureCoordinates(feature.getGeometry().getCoordinates())

    }
    useEffect(() => {
        const selectedLayerNames: any[] = (window as any).selectedLayerNames
        const selectedFeatureIds: any[] = (window as any).selectedFeatureIds
        const selectedFeatures: any[] = (window as any).selectedFeatures

        const ctrlDown: any = (window as any).ctrlDown
        if (!ctrlDown) {
            selectedLayerNames.splice(0, selectedLayerNames.length)
            selectedFeatureIds.splice(0, selectedFeatureIds.length)
            selectedFeatures.splice(0, selectedFeatures.length)
        }

        if (pano) {
            const layers = pano.getScalable().getLayers();
            if (allSelectedFeatures[0] && allSelectedFeatures.length) {
                for (const layer of layers) {
                    const geoms = layer._geometries;
                    for (const gdh of geoms) {
                        if (allSelectedFeatures.find((feature: any) => feature.id === gdh.attributes.__geomKey)) {
                            // setSelectedGdhFeature(gdh)
                            gdh.select()
                        } else {
                            selectedGdhFeature?.stopVertexEdit()
                            // pano.getScalable()?.getMainSketchLayer().clearAll();
                            // pano.getScalable()?.getMainSketchLayer().refresh();
                            gdh.deselect()
                        }
                    }
                    layer.redraw()
                }
                allSelectedFeatures.forEach((selected: any) => {
                    if (!selectedFeatureIds.find((el: any) => el === selected.id)) {
                        selectedFeatures.push(selected)
                        selectedLayerNames.push(selected?.id?.split('.')[0])
                        selectedFeatureIds.push(selected.id)
                    }
                })
            }
            else {
                selectedLayerNames.splice(0, selectedLayerNames.length)
                selectedFeatureIds.splice(0, selectedFeatureIds.length)
                selectedFeatures.splice(0, selectedFeatures.length)
                for (const layer of layers) {
                    const geoms = layer._geometries;
                    for (const gdh of geoms) {
                        if (gdh.isSelected()) {
                            gdh.deselect()
                        }
                    }
                    layer.redraw()
                }
            }
        }
    }, [allSelectedFeatures])


    const rightClickHandler = (event?: any) => {
        // console.log("rightClickHandler");
        event.preventDefault();
        if (buttonState === ButtonState.VERTEX && selectedGdhFeature && selectedLayer.geomtype !== 'MultiPointZ' /*&& from === "Panorama"*/) {
            selectedGdhFeature.stopVertexEdit()
            // console.log("selectedGdhFeature :",selectedGdhFeature);

            const gdhToGeoJson = selectedGdhFeature.toGeoJSON()
            // console.log("gdhToGeoJson :",gdhToGeoJson);
            delete gdhToGeoJson.ankaProperties
            delete gdhToGeoJson.properties.srid
            delete gdhToGeoJson.properties.bbox
            const geoJson = new GeoJSON();
            let feature: any = geoJson.readFeature(gdhToGeoJson);
            feature.getGeometry().transform('EPSG:4326', 'EPSG:3857')
            feature.setId(selectedGdhFeature.attributes.__geomKey)
            //update için geojson halini gönderiyorum
            setUpdatedOlFeature(gdhToGeoJson)
            setUpdatedFeatureType(feature.getGeometry().getType())
            setUpdatedFeatureCoordinates(feature.getGeometry().getCoordinates())
            // dispatch(setConfirmPopupVisibility(true))
            // console.log("dispatch(setDigiPopupVisibility(true))");
            dispatch(setDigiPopupVisibility(true))
            dispatch(setFrom("Panorama"))

        } else if (buttonState === ButtonState.MOVE) {
            if (gdh_.type === 'Line' || gdh_.type === 'Polygon') {
                selectedGdhFeature.stopMoveEdit();
                gdhToTransaction(selectedGdhFeature, selectedGdhFeature.attributes.__geomKey)
            } else {
                gdh_.stopVertexEdit()
                gdhToTransaction(gdh_, selectedGdhFeature.attributes.__geomKey)
            }
            dispatch(setDigiPopupVisibility(true))// dispatch(setConfirmPopupVisibility(true))
            dispatch(setFrom("Panorama"))
        }
    }

    useEffect(() => {
        if (selectedGdhFeature && (buttonState === ButtonState.VERTEX || buttonState === ButtonState.MOVE)
            /*&& selectedLayer?.name === selectedGdhFeature.attributes.__geomKey.split('.')[0]*/) {
            // dispatch(setDigiPopupVisibility(false))
            // console.log("selectedGdhFeature :",selectedGdhFeature);

            const wkt = selectedGdhFeature.toWKT().replace(/ 0/g, '')
            gdh_ = GeomDataHolder.fromWKT(wkt)
            if (buttonState === ButtonState.VERTEX && selectedLayer.geomtype === 'MultiPointZ') {
                // pano.getScalable()?.getMainSketchLayer().clearAll();
                // setTimeout(() => {

                //     setSelectedGdhFeature(null)
                //     pano.getScalable()?.getMainSketchLayer().clearAll();

                //     pano.getScalable().getMainSketchLayer().ref  resh()
                // }, 100);
                toast.current?.show({ severity: 'info', detail: ('This tool is just for line and polygon layers.'), life: 3000 });

            }

            else if (buttonState === ButtonState.VERTEX && selectedLayer.geomtype !== 'MultiPointZ') {
                selectedGdhFeature.editVertex();
            } else if (buttonState === ButtonState.MOVE) {
                if (gdh_.type === 'Line' || gdh_.type === 'Polygon') {
                    // console.log("zart");
                    pano.getScalable().getMainSketchLayer().addGlobal(selectedGdhFeature)
                    pano.getScalable().getMainSketchLayer().refresh()
                    selectedGdhFeature.editMove()

                } else {
                    pano.getScalable().getMainSketchLayer().addGlobal(gdh_)
                    pano.getScalable().getMainSketchLayer().refresh()
                    gdh_.editVertex()
                }
            }
            document.addEventListener('contextmenu', rightClickHandler)
        }
        if (selectedGdhFeature && buttonState === ButtonState.DELETE) {

        }
        return () => {
            if (selectedGdhFeature && buttonState === ButtonState.VERTEX) {
                selectedGdhFeature.stopVertexEdit();

                // const gdhToGeoJson = selectedGdhFeature.toGeoJSON();
                // delete gdhToGeoJson.ankaProperties
                // delete gdhToGeoJson.properties.srid
                // delete gdhToGeoJson.properties.bbox
                // const geoJson = new GeoJSON();
                // let feature: any = geoJson.readFeature(gdhToGeoJson);
                // feature.getGeometry().transform('EPSG:4326', 'EPSG:3857')
                // feature.setId(selectedGdhFeature.attributes.__geomKey)
                // //update için geojson halini gönderiyorum
                // setUpdatedOlFeature(gdhToGeoJson)
                // setUpdatedFeatureType(feature.getGeometry().getType())
                // setUpdatedFeatureCoordinates(feature.getGeometry().getCoordinates())

                document.removeEventListener('contextmenu', rightClickHandler)
            } else if (selectedGdhFeature && buttonState === ButtonState.MOVE) {
                if (gdh_.type === 'Line' || gdh_.type === 'Polygon') {
                    selectedGdhFeature.stopMoveEdit();
                    gdhToTransaction(selectedGdhFeature, selectedGdhFeature.attributes.__geomKey)
                } else {

                    gdh_.stopVertexEdit()
                    gdhToTransaction(gdh_, selectedGdhFeature.attributes.__geomKey)
                }
                document.removeEventListener('contextmenu', rightClickHandler)
            }
        }
    }, [selectedGdhFeature])

    // heading, roll, pitch değerlerini değiştirmek için
    useEffect(() => {
        if (pano && scable) {
            //console.log("[settings, pano, scable] ",settings);
            scable.setOriantation(settings[0].value - panoConfig[0].value, settings[2].value - panoConfig[2].value,
                settings[1].value - panoConfig[1].value);
        }
    }, [settings, pano, scable])

    /**
    * Panoramaya tıklandığında haritanın da hareket etmesi için.
    */
    const onLocationChange = (event: any) => {
        if ((window as any).panoCoordinate?.lat !== event.lat && (window as any).panoCoordinate?.lon !== event.lon) {
            setLatLon(JSON.stringify({ lat: event.lat, lon: event.lon }))

            dispatch(setPanoramaCoordinate({ lat: event.lat, lon: event.lon }));
            (window as any).panoCoordinate = { lat: event.lat, lon: event.lon }
            const currentPoint = event.currentTarget.getCurrentPoint()
            const shootingDate = currentPoint.stamp;
            panoDate.current = shootingDate === "" || shootingDate === null || !shootingDate ? "false" : shootingDate;

            if (currentPoint.flat) {
                event.currentTarget.lookAtY(currentPoint.heading);
            }
            const label = event.currentTarget.getCurrentPoint().label ? event.currentTarget.getCurrentPoint().label : '';
            dispatch(setPanoramaInfo({ shootingDate: shootingDate, label: label }))
        }
    }

    const dataLoaded = (dataLoadedEvent: any) => {
        const data = dataLoadedEvent.data.rawData;
        const firstPoint = data.closePoints[0];
        const panoDateData = data.closePoints.filter((closePoint: any) => Math.abs(firstPoint.distance - closePoint.distance) < 15);
        setClosestPoints(panoDateData);
    }

    const onCursorMoveOnGround = (event: any) => {
        if (!event.isWall) {
            if (!cursorVisible) {
                dispatch(setCursorVisible(true));
            }
            dispatch(setCursorCoordinate(transform([event.lon, event.lat], 'EPSG:4326', 'EPSG:3857')))
        }
    }
    /**
     * Panorama açısı için.
     */
    const onViewAngleChange = (e: any) => {
        if (e.rotationY === 0 || e.rotationY === 360) {
            return
        }
        else {
            let fov = panogl.controller.getFov();
            const panoValues: any = localStorage.getItem('panoValues')?.split(',')
            const isCopyUrl: any = localStorage.getItem('isCopyUrl')

            if (isCopyUrl === "copied") {

                dispatch(setViewAngle({ rotationX: panoValues[1], rotationY: panoValues[2], fov: panoValues[0] }))
                localStorage.setItem('view', panoValues[0] + ',' + panoValues[1] + ',' + panoValues[2])
            }
            else {
                dispatch(setViewAngle({ rotationX: e.rotationX, rotationY: e.rotationY, fov: fov }))
                localStorage.setItem('view', e.fov + ',' + e.rotationX + ',' + e.rotationY)
            }
        }
        localStorage.setItem('isCopyUrl', "notCopied");
    }

    /**
     * Panorama üzerinde imlecin hareketini dinleyen fonksiyon.
     */
    function onCursorMoveGround(event: any) {
        const coordinate = [event.lon, event.lat];
        setCoordinate(coordinate)
    }

    useEffect(() => {
        document.addEventListener('dblclick', doubleClick)
    }, [])

    useEffect(() => {
        if (panoramaTransactStatus) {
            // console.log(`panoramaTransactStatus`);

            //    pano.getScalable()?.getMainSketchLayer().clearAll();
        }
    }, [panoramaTransactStatus])

    useEffect(() => {
        if (selectedKeysforPano && styles) {
            const removelayers: any[] = [];
            const addlayers: any[] = [];
            const layers_: any[] = pano?.getScalable().getLayers().length ? [...pano?.getScalable().getLayers()] : [];
            const selectedKeyNames: any = []
            for (const [key] of Object.entries(selectedKeysforPano)) {
                selectedKeyNames.push(key)
            }
            selectedKeyNames.forEach((selectedKeyName: string) => {
                const newlayer_: boolean = layers_.length === 0 ? selectedKeyName.includes("_usr") && !selectedKeyName.includes("domain")
                    : !layers_.find((el: any) => el.name === selectedKeyName) && !selectedKeyName.includes("domain") && selectedKeyName.includes("_usr")
                if (newlayer_) {
                    /** ekle */
                    projects.forEach((project: Project) => {
                        project.layer_groups.forEach((layerGroup: any) => {
                            layerGroup.layers.forEach((layer: any) => {
                                if (layer.name === selectedKeyName) {
                                    const url: string = services.find((service: any) => service.id === layer.service_id)?.url
                                    const serviceType: string = services.find((service: any) => service.id === layer.service_id)?.type
                                    if (serviceType === 'VECTOR') {
                                        // addlayers
                                        if (!layers_.find((el: any) => el.name === selectedKeyName)) {
                                            // console.log("ekle : ",selectedKeyName)
                                            addLayer(url, null, layer.name, layer.fields);
                                            addlayers.push(selectedKeyName)
                                        }
                                    }
                                }
                            })
                        })
                    })
                }
                // console.log("selectedKeyName :",selectedKeyName);
                const existUnvisiblelayer_: any = layers_.find((el: any) => el.name === selectedKeyName && !el.visible);
                // console.log("existUnvisiblelayer_ :",existUnvisiblelayer_);
                if (existUnvisiblelayer_) {
                    const case_: boolean = !selectedKeyName.includes("domain") && selectedKeyName.includes("_usr")
                        && !selectedKeyNames.find((el: any) => el.includes("/" + selectedKeyName))
                    if (case_) {
                        existUnvisiblelayer_.visible = true
                    }
                }
            });

            layers_.forEach((layer_: any) => {
                if (!selectedKeyNames.find((name: any) => name === layer_.name)) {
                    removelayers.push(layer_)
                }
            });
            removelayers.forEach((layer_: any) => {
                /*const unvisibled = pano?.getScalable().getLayers().find((el:any)=> el.name === layer_.name);*/
                const unvisibleds = pano?.getScalable().getLayers().filter((el: any) => el.name === layer_.name);
                /*if(!selectedKeyNames.find((name: any) => name.includes("/" + layer_.name))){
                    unvisibled.visible = false;
                }*/
                // if(!visibleDomainObjs.find((el:any)=>el.layername === layer_.name)){
                if (unvisibleds.length) {
                    // unvisibleds.forEach((unvisibled:any) => {
                    //     console.log("unvisibled : ",layer_.name)
                    //     unvisibled.visible = false;
                    // });
                    layer_.visible = false;
                    // console.log("unvisibled : ",layer_.name)
                }
                // }
                // else{
                //     // unvisibled.visible = false;
                //     // unvisibled.removeEvent(AnkaScalable.GeoServerWFSLayer.FEATURE_CLICK, selectGdhFeature)
                //     if(unvisibled){
                //         console.log("sil DOM__ : ",unvisibled.name)
                //         pano?.getScalable().removeLayer(unvisibled);
                //     }
            });
            const visibleDomainlist: any = []
            for (const [key] of Object.entries(selectedKeysforPano)) {
                if (key.includes('domain')) {
                    const arr = key.split('/')
                    visibleDomainlist.push({ filterTag: arr[1], domainId: arr[2], layername: arr[3] })
                }
            }
            /*     console.log(visibleDomainlist);
            console.log(visibleDomainObjs); */
            let domainlayers_: any = [];
            // console.log(addlayers);
            // console.log(removelayers);

            // if(!addlayers.length && !removelayers.length){
            if (visibleDomainlist.length > visibleDomainObjs.length) {
                visibleDomainlist.filter((visibleDomain: any) =>
                    !visibleDomainObjs.find((visibleDomainObj: any) => visibleDomainObj.domainId === visibleDomain.domainId && visibleDomainObj.layername === visibleDomain.layername)
                ).forEach((layer: any) => {
                    if (!domainlayers_.find((el: any) => el === layer.layername)) {
                        domainlayers_.push(layer.layername)
                    }
                });
            } else {
                /* console.log("else",visibleDomainObjs.find((visibleDomainObj:any) =>
                !visibleDomainlist.find((visibleDomain:any)=>
                    visibleDomainObj.domainId === visibleDomain.domainId && visibleDomainObj.layername === visibleDomain.layername
                )
            )) */
                visibleDomainObjs.filter((visibleDomainObj: any) =>
                    !visibleDomainlist.find((visibleDomain: any) =>
                        visibleDomainObj.domainId === visibleDomain.domainId && visibleDomainObj.layername === visibleDomain.layername
                    )
                )?.forEach((layer: any) => {
                    if (!domainlayers_.find((el: any) => el === layer.layername)) {
                        domainlayers_.push(layer.layername)
                    }
                });
            }
            // }
            // console.log("domainlayers_ :",domainlayers_);

            domainlayers_.forEach((domainlayer_: any) => {
                const dlayer_: any = layers_.find((el: any) => el.name === domainlayer_);
                // console.log("dlayer_ : ",dlayer_);

                projects.forEach((project: Project) => {
                    project.layer_groups.forEach((layerGroup: any) => {
                        layerGroup.layers.forEach((layer: any) => {

                            if (layer.name === domainlayer_ && !removelayers.find((el: any) => el.name === domainlayer_)
                                && !addlayers.find((el: any) => el === domainlayer_)) {
                                const url: string = services.find((service: any) => service.id === layer.service_id)?.url
                                const serviceType: string = services.find((service: any) => service.id === layer.service_id)?.type
                                if (serviceType === 'VECTOR') {
                                    // console.log("refresh : ",dlayer_);
                                    if (dlayer_) {
                                        // dlayer_.removeEvent(AnkaScalable.GeoServerWFSLayer.FEATURE_CLICK, selectGdhFeature)
                                        // pano.getScalable()._layers.find((layer: any) => {
                                        dlayer_.visible = false
                                        // })
                                        let ff = pano.getScalable()._layers.find((el: any) => el.name === dlayer_.name)
                                        ff = undefined;
                                        // pano?.getScalable().removeLayer(dlayer_);
                                    }
                                    addLayer(url, null, layer.name, layer.fields);
                                    // updateStyeDom(url, null, layer.name, layer.fields)
                                }
                            }
                        })
                    })
                })
            });
        }
    }, [selectedKeysforPano]);

    useEffect(() => {
        /*         console.log("panoramaTransactStatus : ",panoramaTransactStatus);
                console.log("cancelDigiActivate : ",cancelDigiActivate); */
        if (!cancelDigiActivate && !panoramaTransactStatus /*=== false*/) {
            // console.log("pano && styles");
            const layer_: any = pano?.getScalable()._layers.find((layer: any) =>
                layer.name === selectedLayer?.name
            )
            layer_?.refresh();
            // console.log("refresh : ",layer_);
            /*   pano.getScalable()._layers = []
              projects.map((project: Project) => {
                  project.layer_groups.map((layerGroup: any) => {
                      layerGroup.layers.map((layer: any) => {
                          if (layer.visible) {
                              const url = services.find((service: any) => service.id === layer.service_id)?.url
                              const serviceType = services.find((service: any) => service.id === layer.service_id)?.type
                              if (serviceType === 'VECTOR') {
                                  addLayer(url, null, layer.name, layer.fields);
                              }
                          }
                      })
                  })
  
              }) */
        }
    }, [cancelDigiActivate, panoramaTransactStatus]);

    useEffect(() => {
        /*
          console.log("pano : ",pano);
          console.log(" styles : ", styles);
        * kontrol edilmeli
        */
        if (pano && styles /*&& !cancelDigiActivate && panoramaTransactStatus === false*/) {
            // console.log("pano && styles");
            pano.getScalable()._layers.find((layer: any) => {
                layer.visible = false
            })
            pano.getScalable()._layers = []
            projects.map((project: Project) => {
                project.layer_groups.map((layerGroup: any) => {
                    layerGroup.layers.map((layer: any) => {
                        if (layer.visible) {
                            
                            const url = services.find((service: any) => service.id === layer.service_id)?.url
                            visibleLayerDict[layer.name] = layer.fields
                            const serviceType = services.find((service: any) => service.id === layer.service_id)?.type
                            if (serviceType === 'VECTOR') {
                                addLayer(url, null, layer.name, layer.fields);
                            }
                        }
                    })
                })

            })
        }
    }, [pano, /*projects, */styles/*, cancelDigiActivate, panoramaTransactStatus*/])

    useEffect(() => {
        //floor Sayısallaştırma için

        // if(buttonState === ButtonState.ADD){
        //     pano.getScalable()?.getMainSketchLayer().clearAll();
        // }
        (window as any).buttonState = buttonState
        // console.log("(window as any).buttonState :",(window as any).buttonState);
        if(panoramaType==="Floor"){
            draw?.stopDrawingForAll()
        } else if(panoramaType==="Point Cloud"){
            pointCloudPlugin_?.stopDraw()
        } else if(panoramaType==="Multi Display"){
            multiMeasurePlugin?.stopMultiMeasureInAllInstances();

        }
        setTimeout(() => {

            if (buttonState === ButtonState.ADD) {
                if (panoramaType === 'Floor') {
                    // if (buttonState !== ButtonState.NONE) {
                    //      console.log("Floor Floor Floor");

                    //     dispatch(setDrawState({ drawStateType: DrawStateType.NONE }))
                    // }
                    if (buttonState === ButtonState.ADD && selectedLayer.geomtype === 'MultiPointZ') {
                        draw?.startPoint();
                    }
                    if (buttonState === ButtonState.ADD && selectedLayer.geomtype === 'MultiLineStringZ') {
                        draw?.startLine();
                    }
                    if (buttonState === ButtonState.ADD && selectedLayer.geomtype === 'MultiPolygonZ') {
                        draw?.startPolygon();
                    }
                    if (buttonState !== ButtonState.ADD && pano) {
                        draw?.stopDrawingForAll();

                    }
                }
                //panoromada PC cizim için
                if (panoramaType === 'Point Cloud') {
                    // if (buttonState !== ButtonState.NONE) {
                    //     dispatch(setDrawState({ drawStateType: DrawStateType.NONE }))
                    // }

                    // draw?.stopDrawingForAll();
                    // dispatch(setDrawState({ drawStateType: DrawStateType.NONE }))

                    // pointCloudPlugin_?.stopDraw()
                    // if (buttonState !== ButtonState.NONE) {
                    //     console.log("Point Cloud Point Cloud Point Cloud");

                    //    dispatch(setDrawState({ drawStateType: DrawStateType.NONE }))
                    // }
                    if (buttonState === ButtonState.ADD && selectedLayer.geomtype === 'MultiPointZ') {
                        pointCloudPlugin_?.startDrawing(DrawStatesPC.POINT_PC_DRAW);
                        (window as any).currentDraw = DrawStateType.PANORAMA_POİNT_PC
                    }
                    else if (buttonState === ButtonState.ADD && selectedLayer.geomtype === 'MultiLineStringZ') {
                        pointCloudPlugin_?.startDrawing(DrawStatesPC.LINE_PC_DRAW);
                        (window as any).currentDraw = DrawStateType.PANORAMA_LINE_PC
                    }
                    if (buttonState === ButtonState.ADD && selectedLayer.geomtype === 'MultiPolygonZ') {
                        pointCloudPlugin_?.startDrawing(DrawStatesPC.POLYGON_PC_DRAW);
                        (window as any).currentDraw = DrawStateType.PANORAMA_POLYGON_PC
                    }
                }
                //panoromada multidisplay için
                if (panoramaType === 'Multi Display') {
                    //     if (buttonState !== ButtonState.NONE) {
                    //         console.log("Multi Display Multi Display Multi Display");

                    //        dispatch(setDrawState({ drawStateType: DrawStateType.NONE }))
                    //    }
                    if (buttonState === ButtonState.ADD && selectedLayer.geomtype === 'MultiPointZ') {
                        multiMeasurePlugin?.startMultiMeasure(null, MultiDrawTypes.DIGI_POINT_MULTI);
                        (window as any).currentDraw = MultiDrawTypes.DIGI_POINT_MULTI
                    }
                    if (buttonState === ButtonState.ADD && selectedLayer.geomtype === 'MultiLineStringZ') {
                        multiMeasurePlugin?.startMultiMeasure(null, 'DEFAULT');
                        (window as any).currentDraw = MultiDrawTypes.DIGI_LINE_MULTI
                    }
                    if (buttonState === ButtonState.ADD && selectedLayer.geomtype === 'MultiPolygonZ') {
                        multiMeasurePlugin?.startMultiMeasure(null, 'POLYGON');
                        (window as any).currentDraw = MultiDrawTypes.DIGI_POLYGON_MULTI
                    }
                }
            }
        }, 200);
    }, [buttonState, /*selectedLayer,*/])

    /**
     * Panoramaya çift tıklama ile parlaklık ilk geldiği değere dönüyor.
     */
    const doubleClick = (event: any) => {
      /*   event.preventDefault();
        setBrightness(25);
        (window as any).pano.setBrightness(1); */
    }

    const showPointCloud = (value: any) => {
        setLidarChecked(!lidarChecked)
        pano.getCloudInstance().enablePoints = value;
    }
    const getToken = () => {
        return localStorage.getItem('token')
    }


    const uidNotFind = (st: any) => {
        const uids: any = (window as any).drawUids ? (window as any).drawUids : [];
        if (!uids.find((el: any) => el === st.uid)) {
            return true;
        } else {
            return false;
        }
    }

    const updateUids = (st: any) => {
        const currentType: string = (window as any).drawStateType;
        if (!otherStates.find((el: any) => el === currentType)) {
            const uids: any = (window as any).drawUids ? (window as any).drawUids : [];
            if (!uids.find((el: string) => el === st.uid)) {
                uids.push(st.uid)
            }
            (window as any).drawUids = uids;
        }
    }
    const drawStatusChange = (event: any) => {
        // console.log("event :",event);
        
        setEventStatus(event)
        if (event.status === 'POINT_ADDED') {
            const points: any = event.feature?.points

            // if(+(window as any).innerWidth <= 680 && points.length === 1 && (window as any).mobileDrawPoint === false){
            //     (window as any).mobileDrawPoint = true;
            //     const drawtype :any = (window as any).drawStateType
                
            //     dispatch(setDrawState({ drawStateType: DrawStateType.NONE }))
            //     setTimeout(() => {
            //         dispatch(setDrawState({ drawStateType: drawtype}/*(window as any).drawStateType*/))
            //     }, 0);
            //     if(event.feature.type === "Point"){
            //         setTimeout(() => {
            //          (window as any).panogl?.getScalable()?.getMainSketchLayer().clearAll()
            //         }, 10);
            //     }
            //     return
            // }
            if (points.length === 2) {
                const height: any = Math.abs(points[0].alt - points[1].alt);
                if (typeof height === 'number') {
                    dispatch(setHeightVal(parseFloat(height.toFixed(2))))
                    dispatch(setHeightMeasureActive(false))
                }
            }
        }
        if (event.status === 'POINT_ADDED' && (window as any).currentDraw === DrawStateType.PANORAMA_TRIANGLE_PC) {
            // console.log("POINT_ADDED");

            const feature = event.feature;
            if (feature && feature.points && feature.points.length > 2)
            {
                let e2 = event
                e2.status ='FINISHED'
                e2.feature.points = event.feature?.points.slice(0,2)
                panogl.getCloudInstance()._onToolStatusComplete(e2)
                dispatch(setDrawState({ drawStateType: DrawStateType.DEFAULT }))
                panogl.getScalable().getMainSketchLayer().refresh();
            }
            else if (feature && feature.points && feature.points.length === 2) {
                let firstPoint;
                let secondPoint;
                firstPoint = feature.points[0];
                secondPoint = feature.points[1];
                // Eğer İkinci Nokta daha yüksek ise
                if (feature.points[0].alt < feature.points[1].alt) {
                    firstPoint = feature.points[1];
                    secondPoint = feature.points[0];
                }
                // İlk noktanın kordinatları.
                const lon1 = firstPoint.lon;
                const lat1 = firstPoint.lat;
                const alt1 = firstPoint.alt;
                // İkinci noktanın kordinatları.
                const lon2 = secondPoint.lon;
                const lat2 = secondPoint.lat;
                const alt2 = secondPoint.alt;
                // kordinatlardan wkt oluşturma.
                const firstLineString = `MULTILINESTRING((${lon1} ${lat1} ${alt1} ,${lon1} ${lat1} ${alt2}))`;
                const secondLineString = `MULTILINESTRING((${lon1} ${lat1} ${alt2} ,${lon2} ${lat2} ${alt2}))`;
                const thirdLineString = `MULTILINESTRING((${lon1} ${lat1} ${alt1} ,${lon2} ${lat2} ${alt2}))`;
                // kordinatlardan geomdataholder oluşturma.
                const firstLineGDH = GeomDataHolder.fromWKT(firstLineString);
                const secondLineGDH = GeomDataHolder.fromWKT(secondLineString);
                const thirdLineGDH = GeomDataHolder.fromWKT(thirdLineString);
                // geomdataholderların çizim katmanına eklenmesi.
                panogl.getScalable().getMainSketchLayer().addGlobal(firstLineGDH);
                panogl.getScalable().getMainSketchLayer().addGlobal(secondLineGDH);
                panogl.getScalable().getMainSketchLayer().addGlobal(thirdLineGDH);
                // çizim katmanının refresh olması.
                panogl.getScalable().getMainSketchLayer().refresh();
                /**  ?  ?  ?    */
                //dispatch(setButtonState(ButtonState.NONE))
                if ((window as any).currentDraw === 'triangle') {
                //     /** Hline */
                //     // console.log("FINISHED triangle ", event.feature)
                    updateUids(event.feature)
                    // panogl.getCloudInstance().stopDraw()
                    // dispatch(setDrawState({ drawStateType: DrawStateType.NONE }))
                    
                 }
            }
        }
        //point clound ile sayısallastırma için
        else if (event.status === 'FINISHED' && event.isSuccessful && (
            (window as any).currentDraw === DrawStateType.PANORAMA_POİNT_PC ||
            (window as any).currentDraw === DrawStateType.PANORAMA_LINE_PC ||
            (window as any).currentDraw === DrawStateType.PANORAMA_POLYGON_PC)) {
            // const newFeature = event.feature;
            // console.log("FINISHED");
            
            dispatch(setDrawState({ drawStateType: DrawStateType.NONE }))

            // console.log("FINISHED",event.feature);
            updateUids(event.feature)

            const points = event.feature.points
            let coordinates: any = [];
            // setDigiOlFeature(null)
            if (points.length >= 0) {
                points.find((point: any) => {
                    coordinates.push([point.lon, point.lat, point.alt])
                })
            }
            const featureType = event.feature.type;
            //buralarrrrr
            let newFeature: any;
            let text: any;
            if (featureType === 'Point') {
                newFeature = new Point(coordinates[0])
                let coord = coordinates[0]
                coord[0] = coord[0].toFixed(6)
                coord[1] = coord[1].toFixed(6)
                text = coord
                //feature pointse ve sayısallastırma add aktifse
            }
            if (featureType === 'Line') {
                newFeature = new LineString(coordinates)
                text = event.feature.attributes._length
                //feature linesa ve sayısallastırma add aktifse
            }
            if (featureType === 'Polygon') {
                coordinates.push(coordinates[0])
                newFeature = new Polygon([coordinates])
                text = event.feature.attributes._areaSize // + '㎡'
            }
            if (featureType === 'Hline') {
                coordinates.push(coordinates[0])
                newFeature = new Point(coordinates[0])
                text = event.feature.attributes._length
                // console.log("event.feature.attributes :", event.feature.attributes);
            }
            if (newFeature) {
                // const gdhToGeoJson = newFeature.toGeoJSON();
                // delete gdhToGeoJson.ankaProperties
                // delete gdhToGeoJson.properties.srid
                // delete gdhToGeoJson.properties.bbox
                // gdhToGeoJson.geometry.coordinates[0] = [...gdhToGeoJson.geometry.coordinates[0], gdhToGeoJson.geometry.coordinates[0][0]];
                // console.log(gdhToGeoJson.geometry.coordinates);
                const feature: any = new Feature({
                    geometry: newFeature
                })
                // const geoJson = new GeoJSON();
                // let olFeature: any = geoJson.readFeature(gdhToGeoJson);
                feature.getGeometry().transform('EPSG:4326', 'EPSG:3857')
                feature.setGeometryName('geom')
                const geoJsonFeature = new GeoJSON().writeFeaturesObject([feature]);
                setTimeout(() => {
                    const result = {
                        type: event.feature.type,
                        feature: { features: [geoJsonFeature] },
                        text: text
                    }
                    setDrawEnd(true)

                    // console.log("(window as any).buttonState :",(window as any).buttonState);
                    // console.log("(window as any).currentDraw :",(window as any).currentDraw);
                    dispatch(setPanoCoordinates(result))
                    if ((window as any).buttonState === ButtonState.ADD && !(window as any).drawStartOfDigi) {
                        // console.log("PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP");
                        (window as any).drawWithDigi = true;
                        setDigiOlFeature(feature)
                        setFeatureType(event.feature.type)
                        setFeatureCoordinates(newFeature.getCoordinates())
                    } else {
                        (window as any).drawWithDigi = false;
                    }

                    if ((window as any).buttonState === ButtonState.NONE) {
                        setDigiOlFeature(null)
                        if (!(window as any).drawWithDigi) {
                            dispatch(setDigiPopupVisibility(false))
                        }
                        // console.log("drawStateType: DrawStateType.NONE");

                        dispatch(setDrawState({ drawStateType: DrawStateType.NONE }))
                    }

                }, 100)
                return () => {
                    setDigiOlFeature(null)
                    setFeatureCoordinates(null)
                    setDrawEnd(false)

                    if (drawState.drawStateType !== DrawStateType.NONE) {
                        dispatch(setDigiPopupVisibility(false))
                        // console.log("dispatch(setDigiPopupVisibility(false))");
                        // console.log("drawStateType: DrawStateType.NONE");
                        dispatch(setDrawState({ drawStateType: DrawStateType.NONE }))
                    }
                }
            }
        }
        else {
            try {
                /**
                 *
                 * try{} catch{} geçici olarak eklendi, handle edildikten sonra
                 * kaldırılacak.
                 *
                 */
                // const points = event.target.points
                // let coordinates: any = [];
                // // setDigiOlFeature(null)
                // if (points.length >= 0) {
                //     points.find((point: any) => {
                //         coordinates.push([point.lon, point.lat])
                //     })
                // }
                if (event.status === 'FINISHED' && event.isSuccessful) {
                    dispatch(setDrawState({ drawStateType: DrawStateType.NONE }))
                    // console.log("FINISHED");
                    // console.log("FINISHED triangle",event.feature)
                    updateUids(event.feature)

                    const featureType = event.target.type;
                    const gdhToGeoJson = event.feature.toGeoJSON();
                    const coordinates = gdhToGeoJson.geometry.coordinates;
                    let newFeature: any;
                    let text: any;
                    if (featureType === 'Point') {
                        newFeature = new Point(coordinates[0])
                        let coord = coordinates[0]
                        coord[0] = coord[0].toFixed(6)
                        coord[1] = coord[1].toFixed(6)
                        text = coord
                        //feature pointse ve sayısallastırma add aktifse
                    }
                    if (featureType === 'Line') {
                        newFeature = new LineString(coordinates)
                        text = event.feature.attributes._length
                        // console.log("event.feature.attributes :",event.feature.attributes);
                        //feature linesa ve sayısallastırma add aktifse
                    }
                    if (featureType === 'Polygon') {
                        //add feature yapılırken çift coordinat gittiği için yoruma alındı.
                        // coordinates.push(coordinates[0])
                        newFeature = new Polygon(coordinates)
                        text = event.feature.attributes._areaSize // + '㎡'
                    }
                    if (featureType === 'Hline') {
                        coordinates.push(coordinates[0])
                        newFeature = new Point(coordinates[0])
                        text = event.feature.attributes._length
                    }
                    setTimeout(() => {
                        //aynı çizimi openlayers map de göstermek için
                        const feature: any = new Feature({
                            geometry: newFeature,
                        })
                        feature.getGeometry().transform('EPSG:4326', 'EPSG:3857')
                        feature.setGeometryName('geom')
                        const geoJsonFeature = new GeoJSON().writeFeaturesObject([feature]);
                        const result = {
                            type: event.target.type,
                            feature: geoJsonFeature,
                            text: text
                        }
                        setDrawEnd(true)
                        dispatch(setPanoCoordinates(result))
                        if ((window as any).buttonState === ButtonState.ADD && !(window as any).drawStartOfDigi /*(window as any).currentDraw === DrawStateType.NONE*/) {
                            // console.log("aaaaaaaaaaaaaaaaaaaaaaaa");
                            (window as any).drawWithDigi = true;
                            setDigiOlFeature(feature)
                            setFeatureType(event.target.type)
                            setFeatureCoordinates(newFeature.getCoordinates())
                        } else {
                            (window as any).drawWithDigi = false;
                        }

                        if ((window as any).buttonState === ButtonState.NONE) {
                            setDigiOlFeature(null)
                            if (!(window as any).drawWithDigi) {
                                dispatch(setDigiPopupVisibility(false))
                                // console.log("dispatch(setDigiPopupVisibility(false))");
                            }
                            // console.log("drawStateType: DrawStateType.NONE");
                            //    dispatch(setDrawState({ drawStateType: DrawStateType.NONE }))
                            // console.log("button : ",buttonState);
                            // console.log("draw : ",drawState);

                        }
                        /**  ?  ?  ?    */
                        // dispatch(setButtonState(ButtonState.NONE))
                        // dispatch(setDrawState({ drawStateType: DrawStateType.NONE }))
                    }, 100)
                }
                // return () => {
                //     setDigiOlFeature(null)
                //     setFeatureCoordinates(null)
                //     setDrawEnd(false)
                // }
            } catch {
                if (event.status === 'FINISHED' && event.isSuccessful) {
                    // console.log("FINISHED triangle",event.feature)
                    updateUids(event.feature)
                    // dispatch(setButtonState(ButtonState.NONE))
                    // dispatch(setDrawState({ drawStateType: DrawStateType.NONE }))
                    if ((window as any).buttonState === ButtonState.NONE) {
                        setDigiOlFeature(null)
                        dispatch(setDigiPopupVisibility(false))
                        // console.log("dispatch(setDigiPopupVisibility(false))");
                        // console.log("drawStateType: DrawStateType.NONE");
                        dispatch(setDrawState({ drawStateType: DrawStateType.NONE }))
                    }
                }
            }
        }
        return () => {
            // dispatch(setButtonState(ButtonState.NONE))
            // dispatch(setDrawState({ drawStateType: DrawStateType.NONE }))
            setDigiOlFeature(null)
            setDrawEnd(false)
            setFeatureCoordinates(null)

            if ((window as any).buttonState === ButtonState.NONE) {
                dispatch(setDigiPopupVisibility(false))
                // console.log("drawStateType: DrawStateType.NONE");
                dispatch(setDrawState({ drawStateType: DrawStateType.NONE }))
            }
        }
    }

    const cancelMultiDraw = () => {
        //  console.log("cancelMultiDraw",(window as any).drawWithDigi);
        if (!(window as any).drawWithDigi) {

            dispatch(setDigiPopupVisibility(false))
            dispatch(setButtonState(ButtonState.NONE))

            const st = pano.getScalable().getMainSketchLayer().getGeomListClone()[pano.getScalable().getMainSketchLayer().getGeomListClone().length - 1]
            // console.log("st:",st);
            if (st && uidNotFind(st)) {
                pano.getScalable().getMainSketchLayer().removeFromGlobal(st)
                pano.getScalable().getMainSketchLayer().refresh();
            }
            dispatch(setFrom(""))
        }
        multiMeasurePlugin?.stopMultiMeasureInAllInstances();
        //  pano.getScalable()?.getMainSketchLayer().clearAll();

        dispatch(setDrawState({ drawStateType: DrawStateType.NONE }))
    }

    const multiDrawStatusChange = async (event: any) => {
        //  console.log("multiDrawStatusChange  ",event)
        // console.log("selectedLayer:",(window as any).selectedLayer)
        /*     console.log("ANCHOR_ADDED",event);
               console.log(isFirstAnchor && event.status && event.status === 'ANCHOR_ADDED'); */
        if (isFirstAnchor && event.status && event.status === 'ANCHOR_ADDED') {
            const rotationY: any = localStorage.getItem('view')?.split(',')[2];
            pano_2.lookAtY((+rotationY).toFixed(4));
            pano_3.lookAtY((+rotationY).toFixed(4));
            dispatch(setViewAngle2({ rotationX: angle.rotationX, rotationY: (+rotationY).toFixed(4), fov: angle.fov }))
            dispatch(setViewAngle3({ rotationX: angle.rotationX, rotationY: (+rotationY).toFixed(4), fov: angle.fov }))
            isFirstAnchor = false;
        }
        if (event.status && event.status === 'FINISHED') {
            /*    dispatch(setViewAngle2({ rotationX: angle.rotationX, rotationY: angle.rotationY, fov: angle.fov }))
            dispatch(setViewAngle3({ rotationX: angle.rotationX, rotationY: angle.rotationY, fov: angle.fov })) */
            // console.log("FINISHED 0 ",event.targetGeom)
            updateUids(event.targetGeom)
            isFirstAnchor = true;
        }
        setEventStatus(event)
        if (event.status === 'FINISHED' && event.isSuccessful && !(window as any).selectedLayer.geomtype?.includes(event.drawType) && (window as any).buttonState === ButtonState.ADD) {
            // console.log("FINISHED 1 ",event.feature)
            cancelMultiDraw()
        } else if (event.status === 'FINISHED' && event.isSuccessful
            && ((window as any).drawStateType === 'multiPoint'
                || (window as any).drawStateType === 'multiLine'
                || (window as any).drawStateType === 'multiPolygon') && !(window as any).drawStateType.includes(event.drawType)) {

            // console.log("FINISHED 2 ",event.feature)

            // console.log("cancelMultiDraw",(window as any).drawStateType);
            cancelMultiDraw()
        }
        else if (event.status === 'FINISHED' && event.isSuccessful) {
            if ((window as any).currentDraw === MultiDrawTypes.DIGI_POINT_MULTI ||
                (window as any).currentDraw === MultiDrawTypes.DIGI_LINE_MULTI ||
                (window as any).currentDraw === MultiDrawTypes.DIGI_POLYGON_MULTI) {

                // console.log("FINISHED 3 ",event.feature)

                const featureType = event.drawType;
                const coordinates = event.targetGeom.points[0]
                const coordArr = [coordinates.lon, coordinates.lat, coordinates.alt]

                let newFeature: any;
                let text: any;
                if (featureType === 'Point') {
                    newFeature = new Point(coordArr)
                    let coord: any = [+coordArr[0].toFixed(6), +coordArr[1].toFixed(6)]
                    text = coord
                    //feature pointse ve sayısallastırma add aktifse
                }
                if (featureType === 'Line') {
                    const lineCoordArr: any[] = []
                    for (const point of event.targetGeom.points) {
                        lineCoordArr.push([point.lon, point.lat, point.alt])
                    }
                    newFeature = new LineString(lineCoordArr)
                    text = event.targetGeom.attributes._length
                    //feature linesa ve sayısallastırma add aktifse
                }
                if (featureType === 'Polygon') {
                    const polygonCoordArr: any[] = []
                    for (const point of event.targetGeom.points) {
                        polygonCoordArr.push([point.lon, point.lat, point.alt])
                    }
                    polygonCoordArr.push(polygonCoordArr[0])
                    let coord = polygonCoordArr
                    newFeature = new Polygon([coord])
                    text = event.targetGeom.attributes._areaSize // + '㎡'
                }

                if (newFeature) {
                    //aynı çizimi openlayers map de göstermek için
                    const feature: any = await new Feature({
                        geometry: newFeature,
                    })
                    feature.getGeometry().transform('EPSG:4326', 'EPSG:3857')
                    feature.setGeometryName('geom')
                    const geoJsonFeature = new GeoJSON().writeFeaturesObject([feature]);
                    const result = {
                        type: featureType,
                        feature: geoJsonFeature,
                        text: text
                    }
                    setDrawEnd(true)
                    dispatch(setPanoCoordinates(result))
                    if ((window as any).buttonState === ButtonState.ADD && !(window as any).drawStartOfDigi) {
                        (window as any).drawWithDigi = true;
                        setDigiOlFeature(feature)
                        setFeatureType(featureType)
                        setFeatureCoordinates(newFeature.getCoordinates())
                    } else {
                        (window as any).drawWithDigi = false;
                    }



                    /**
                     * ??????????
                     * 
                     */
                    if ((window as any).buttonState === ButtonState.NONE) {
                        setDigiOlFeature(null)
                        if (!(window as any).drawWithDigi) {
                            dispatch(setDigiPopupVisibility(false))
                        }
                        dispatch(setDrawState({ drawStateType: DrawStateType.NONE }))
                    }


                }

                // if (featureType === undefined) {
                //     if (event.currentTarget.currentPolygonStyle === 'HLINE') {
                //         const hlineCoordArr: any[] = []
                //         for (const point of event.targetGeom.points) {
                //             hlineCoordArr.push([point.lon, point.lat, point.alt])
                //         }
                //         newFeature = new Point(hlineCoordArr[0])
                //         text = event.targetGeom.attributes._length
                //     }
                // }
            }


            if ((window as any).currentDraw !== MultiDrawTypes.DIGI_POINT_MULTI &&
                (window as any).currentDraw !== MultiDrawTypes.DIGI_LINE_MULTI &&
                (window as any).currentDraw !== MultiDrawTypes.DIGI_POLYGON_MULTI) {
                const featureType = event.drawType;
                const coordinates = event.targetGeom.points[0]
                const coordArr = [coordinates.lon, coordinates.lat, coordinates.alt]

                let newFeature: any;
                let text: any;
                if (featureType === 'Point') {
                    newFeature = new Point(coordArr)
                    let coord: any = [+coordArr[0].toFixed(6), +coordArr[1].toFixed(6)]
                    text = coord
                    //feature pointse ve sayısallastırma add aktifse
                }
                if (featureType === 'Line') {
                    const lineCoordArr: any[] = []
                    for (const point of event.targetGeom.points) {
                        lineCoordArr.push([point.lon, point.lat, point.alt])
                    }
                    newFeature = new LineString(lineCoordArr)
                    text = event.targetGeom.attributes._length
                    //feature linesa ve sayısallastırma add aktifse
                }
                if (featureType === 'Polygon') {
                    const polygonCoordArr: any[] = []
                    for (const point of event.targetGeom.points) {
                        polygonCoordArr.push([point.lon, point.lat, point.alt])
                    }
                    let coord = [[polygonCoordArr, polygonCoordArr]]
                    coord = coord[0]
                    newFeature = new Polygon(coord)
                    text = event.targetGeom.attributes._areaSize // + '㎡'
                }
                // console.log("drawStateType: DrawStateType.NONE");
                dispatch(setDrawState({ drawStateType: DrawStateType.NONE }))
                if (newFeature) {
                    setTimeout(() => {
                        //aynı çizimi openlayers map de göstermek için
                        const feature: any = new Feature({
                            geometry: newFeature,
                        })
                        feature.getGeometry().transform('EPSG:4326', 'EPSG:3857')
                        feature.setGeometryName('geom')
                        const geoJsonFeature = new GeoJSON().writeFeaturesObject([feature]);
                        const result = {
                            type: featureType,
                            feature: geoJsonFeature,
                            text: text
                        }
                        setDrawEnd(true)
                        dispatch(setPanoCoordinates(result))
                        if ((window as any).buttonState === ButtonState.ADD && !(window as any).drawStartOfDigi) {
                            (window as any).drawWithDigi = true;
                            setDigiOlFeature(feature)
                            setFeatureType(featureType)
                            setFeatureCoordinates(newFeature.getCoordinates())
                        } else {
                            (window as any).drawWithDigi = false;
                        }



                        /**
                         * ??????????
                         * 
                         */
                        if ((window as any).buttonState === ButtonState.NONE) {
                            setDigiOlFeature(null)
                            if (!(window as any).drawWithDigi && (window as any).buttonState !== ButtonState.ADD) {
                                dispatch(setDigiPopupVisibility(false))
                            }
                            dispatch(setDrawState({ drawStateType: DrawStateType.NONE }))
                        }


                    }, 100)
                }
            }
        } else if (event.status === 'BAD_COMPLETE' && !event.isSuccessful) {
            if (!(window as any).drawWithDigi) {
                dispatch(setButtonState(ButtonState.NONE))
                dispatch(setDigiPopupVisibility(false))
            }
            dispatch(setFrom(""))
            isFirstAnchor = true;

            // console.log("BAD_COMPLETE    ",event.feature)


            //  console.log("BAD_COMPLETE : 1780");
            /*    pano.getScalable()?.getMainSketchLayer().clearAll();*/
            /*    const st = pano.getScalable().getMainSketchLayer().getGeomListClone()[pano.getScalable().getMainSketchLayer().getGeomListClone().length-1]
               if(st){
                   pano.getScalable().getMainSketchLayer().removeFromGlobal(st)
                   pano.getScalable().getMainSketchLayer().refresh();
               }
        */
            pano.getScalable().getMainSketchLayer().refresh();
            multiMeasurePlugin?.stopMultiMeasureInAllInstances();
            dispatch(setDrawState({ drawStateType: DrawStateType.NONE }))

        }

        return () => {
            setDigiOlFeature(null)
            setFeatureCoordinates(null)
            setDrawEnd(false)
            // dispatch(setDrawState({ drawStateType: DrawStateType.NONE }))


            /**
             * ??????????
             * 
             */
            if ((window as any).buttonState === ButtonState.NONE) {
                setDigiOlFeature(null)
                dispatch(setDigiPopupVisibility(false))
                dispatch(setDrawState({ drawStateType: DrawStateType.NONE }))
            }

        }
    }

    useEffect(() => {
        if (!digiPopupVisibility) {
            setDigiOlFeature(null)
            setDrawEnd(false)
            setFeatureCoordinates(null)
        } else {
            draw?.stopDrawingForAll()
        }
    }, [digiPopupVisibility])


    useEffect(() => {
        if (buttonState === ButtonState.ADD && digiOlFeature/* && (window as any).drawWithDigi*/) {
            //console.log("if (buttonState === ButtonState.ADD && digiOlFeature) {");
            //burası panoramada sayısallaştırmayı etkiliyor. bakılsın.
            calculateResult(digiOlFeature).then((calcFields: any) => {
                // console.log("calcFields :",calcFields);
                // console.log("selectedLayer.geomtype :",selectedLayer.geomtype);


                if ((calcFields.length === undefined && selectedLayer.geomtype === "MultiLineStringZ")
                    || (calcFields.Area === undefined && selectedLayer.geomtype === "MultiPolygonZ")) {
                    /** terminate */
                    dispatch(setDigiPopupVisibility(false))
                    dispatch(setFrom(""))
                    //  console.log("terminate :");
                    if (selectedGdhFeature && uidNotFind(selectedGdhFeature)) {
                        // console.log("cancelDigiActivate selectedGdhFeature :",selectedGdhFeature);
                        pano.getScalable().getMainSketchLayer().removeFromGlobal(selectedGdhFeature)
                    }
                    // pano.getScalable()?.getMainSketchLayer().clearAll();
                    pano.getScalable().getMainSketchLayer().refresh();

                    dispatch(setButtonState(ButtonState.NONE))

                } else {
                    /* 
                       pano.getScalable()?.getMainSketchLayer().clearAll();
                       pano.getScalable().getMainSketchLayer().refresh(); */
                    dispatch(setCalculatedFields(calcFields));
                    dispatch(setDigiPopupVisibility(true))
                    dispatch(setFrom("Panorama"))
                }

            }).catch((err: any) => {
            })
        }
        else {
            if (!(window as any).drawWithDigi) {
                dispatch(setDigiPopupVisibility(false))
            }
            dispatch(setFrom(""))
        }
    }, [digiOlFeature/*, buttonState*/])

    useEffect(() => {
        if (transactStatus) {
            dispatch(setPanoramaEditStatus(false))
        }
    }, [transactStatus])

    useEffect(() => {
        if (latLon) {
            drawSelections()
        }
    }, [latLon]);

    const drawSelections = () => {
        /**
         * drawSelections !!
         */
        const selectedFeatures = (window as any).selectedFeatures
        setTimeout(() => {
            dispatch(setSelectedFeature([...selectedFeatures]))
        }, 400);
    }




    /**
     * panorama üzerinde wfs dataların çizimi için
     */
    const addLayer = (url: any, workspace: any, name: any, fields: Field[]) => {
        const visibleDomains: any = []
        for (const [key] of Object.entries(selectedKeysforPano)) {
            if (key.includes('domain')) {
                const arr = key.split('/')
                visibleDomains.push({ filterTag: arr[1], domainId: arr[2], layername: arr[3] })
            }
        }
        pano?.getScalable().getLayers().filter((el: any) => el.name === name).forEach((el: any) => el.visible = false)
        /* console.log("visibleDomains :",visibleDomains); */

        // const filterByLayer: any = []
        // visibleDomains.map((domain: any) => {
        //     if (domain.layername === name) {
        //         filterByLayer.push(domain)
        //     }
        // })
        // // console.log("url ;:",url);
        // const cqlFilter = `${filterByLayer && filterByLayer.length > 0 ? filterByLayer.map((domain: any) => `${domain.filterTag}=${+domain.domainId}`).join(' OR ') : `1=1`}`
        // // console.log("cqlFilter ;:",cqlFilter);

        // &CQL_FILTER=(
        //  const wfsLayer = new GeoServerWFSLayer(name, url + `/ows`, `${name}&CQL_FILTER=(${cqlFilter})`, 'geom');
        const wfsLayer = new GeoServerWFSLayer(name, url + '/ows', name, 'geom');
        setWfsLayer(wfsLayer)
        const findedStyle = styles.find((style: any) => style.name === wfsLayer.name)
        const findedStyleArray = styles.filter((style: any) => style.name === wfsLayer.name)
        // console.log("findedStyleArray ",findedStyleArray);
        // console.log("fields :",fields);
        // console.log("visibleDomains : ",visibleDomains);


        if (findedStyle && findedStyle.value_id && findedStyleArray.length > 0) {

            const attributeStyle: any = {};
            for (const style of findedStyleArray) {
                // console.log("style :",style);

                const canvas = document.createElement('canvas');
                const canvasWidhtHeight = 40
                const R = canvasWidhtHeight + (2 * style.stroke_width)
                canvas.height = R;
                canvas.width = R;
                const ctx: any = canvas.getContext('2d');

                const fieldName = fields.find((field: Field) => field.id === style.field_id)?.name
                wfsLayer.setStyleKey(fieldName)
                // console.log("fieldName :",fieldName);

                // console.log("style :",style);

                if (style.is_icon) {
                    const image = new Image();
                    image.setAttribute('crossorigin', 'anonymous');
                    image.src = 'data:image/' + style.icon;
                    const texture = new Three.Texture(image);
                    texture.minFilter = Three.LinearFilter;
                    image.onload = () => {
                        const height = style.height;
                        const width = style.width;
                        const xoffset = style.width * style.offset_x;
                        const yoffset = style.height * style.offset_y;
                        const geom = new Three.PlaneBufferGeometry(width, height, 1, 1);
                        texture.needsUpdate = true;

                        if (visibleDomains.filter((x: any) => x.layername === wfsLayer.name).find((el: any) => +el.domainId === style.value_id) || style.value_id===-1) {
                            attributeStyle[style.value_id] = {
                                texture,
                                width,
                                height,
                                xoffset,
                                yoffset,
                                geometry: geom,
                            };
                            attributeStyle.default = attributeStyle[style.value_id];
                            wfsLayer.setStyle(attributeStyle);

                        } else {
                            attributeStyle[style.value_id] = {
                                texture: new Three.Texture(),
                                //  width: 0,
                                //  height: 0,
                                fillOpacity: 0,
                                lineOpacity: 0,
                                //  xoffset:0,
                                //  yoffset:0,
                                geometry: new Three.PlaneBufferGeometry(0, 0, 0, 0)
                            };
                            // attributeStyle.default = attributeStyle[style.value_id];
                            wfsLayer.setStyle(attributeStyle);
                        }
                        // console.log("visibleDomains ",visibleDomains);
                        // console.log("wfsLayer :",wfsLayer.name);

                        // console.log("visibleDomains.filter((x:any) => x.layername === wfsLayer.name ) :",visibleDomains.filter((x:any) => x.layername === wfsLayer.name ));
                    }
                }
                else if (style.type === 'point') {
                    const image = new Image();
                    if (style.shape === 'circle') {
                        ctx.beginPath();
                        ctx.lineWidth = style.stroke_width;
                        ctx.arc(R / 2, R / 2, (R / 2 - style.stroke_width), 0, 2 * Math.PI);
                        ctx.fillStyle = style.fill_color;
                        ctx.strokeStyle = style.stroke_color;
                        if (style.stroke_width !== 0) {
                            ctx.stroke();
                        }
                        ctx.fill();
                    } else if (style.shape === 'square') {
                        canvas.height = canvasWidhtHeight + style.stroke_width + style.stroke_width;
                        canvas.width = canvasWidhtHeight + style.stroke_width + style.stroke_width;
                        ctx.beginPath();
                        ctx.lineWidth = style.stroke_width;
                        ctx.rect(style.stroke_width, style.stroke_width, canvasWidhtHeight, canvasWidhtHeight);
                        ctx.fillStyle = style.fill_color;
                        ctx.strokeStyle = style.stroke_color;
                        if (style.stroke_width !== 0) {
                            ctx.stroke();
                        }
                        ctx.fill();
                    } else if (style.shape === 'triangle') {
                        ctx.beginPath();
                        ctx.moveTo(style.stroke_width, R - style.stroke_width);
                        ctx.lineTo((canvasWidhtHeight / 2) + style.stroke_width, style.stroke_width);
                        ctx.lineTo(R - style.stroke_width, R - style.stroke_width);
                        ctx.lineWidth = style.stroke_width;
                        ctx.fillStyle = style.fill_color;
                        ctx.strokeStyle = style.stroke_color;
                        ctx.closePath();
                        if (style.stroke_width !== 0) {
                            ctx.stroke();
                        }
                        ctx.fill();
                    }
                    image.src = 'data:image/' + canvas.toDataURL().slice(11, canvas.toDataURL().length);

                    const texture = new Three.Texture(image);
                    texture.minFilter = Three.LinearFilter;
                    image.onload = () => {
                        /*       const height = style.height;
                              const width = style.width;
                              const xoffset = style.width * style.offset_x;
                              const yoffset = style.height * style.offset_y;
                              const geom = new Three.PlaneBufferGeometry(width, height, 1, 1);
                              texture.needsUpdate = true;
                              wfsLayer.setStyle({
                                  texture,
                                  width,
                                  height,
                                  xoffset,
                                  yoffset,
                                  geometry: geom,
                              }); */
                        const height = style.height;
                        const width = style.width;
                        const xoffset = style.width * style.offset_x;
                        const yoffset = style.height * style.offset_y;
                        const geom = new Three.PlaneBufferGeometry(width, height, 1, 1);
                        texture.needsUpdate = true;

                        if (visibleDomains.filter((x: any) => x.layername === wfsLayer.name).find((el: any) => +el.domainId === style.value_id) || style.value_id===-1) {
                            attributeStyle[style.value_id] = {
                                texture,
                                width,
                                height,
                                xoffset,
                                yoffset,
                                geometry: geom,
                            };
                            // attributeStyle.default = attributeStyle[style.value_id];
                            wfsLayer.setStyle(attributeStyle);
                        } else {
                            attributeStyle[style.value_id] = {
                                texture: new Three.Texture(),
                                //  width: 0,
                                //  height: 0,
                                fillOpacity: 0,
                                lineOpacity: 0,
                                //  xoffset:0,
                                //  yoffset:0,
                                geometry: new Three.PlaneBufferGeometry(0, 0, 0, 0)
                            };
                            // attributeStyle.default = attributeStyle[style.value_id];
                            wfsLayer.setStyle(attributeStyle);
                        }
                        // console.log("visibleDomains ",visibleDomains);
                        // console.log("wfsLayer :",wfsLayer.name);
                        // console.log("style :",style);
                        // console.log("visibleDomains.filter((x:any) => x.layername === wfsLayer.name ) :",
                        // visibleDomains.filter((x:any) => x.layername === wfsLayer.name ))
                        // console.log("visibleDomains.filter((x:any) => x.layername === wfsLayer.name ) :",
                        // visibleDomains.filter((x:any) => x.layername === wfsLayer.name ).find((el:any)=> el.domainId === style.value_id)
                        // );

                    };

                    // attributeStyle[style.value_id] = {
                    //     lineWidth: style.stroke_width,
                    //     lineColor: style.stroke_color,
                    //     fillColor: style.fill_color,
                    //     fillOpacity: style.fill_opacity,
                    //     lineOpacity: style.stroke_opacity
                    // };
                }
                else {
                    if (visibleDomains.filter((x: any) => x.layername === wfsLayer.name).find((el: any) => +el.domainId === style.value_id) || style.value_id===-1) {
                        attributeStyle[style.value_id] = {
                            lineWidth: style.stroke_width,
                            lineColor: style.stroke_color,
                            fillColor: style.fill_color,
                            fillOpacity: style.fill_opacity,
                            lineOpacity: style.stroke_opacity
                        };
                        attributeStyle.default = attributeStyle[style.value_id];
                        wfsLayer.setStyle(attributeStyle);
                    } else {
                        attributeStyle[style.value_id] = {
                            texture: new Three.Texture(),
                            //  width: 0,
                            //  height: 0,
                            fillOpacity: 0,
                            lineOpacity: 0,
                            //  xoffset:0,
                            //  yoffset:0,
                            geometry: new Three.PlaneBufferGeometry(0, 0, 0, 0)
                        };
                        // attributeStyle.default = attributeStyle[style.value_id];
                        wfsLayer.setStyle(attributeStyle);
                    }
                    // console.log("visibleDomains ",visibleDomains);
                    // console.log("wfsLayer :",wfsLayer.name);
                    // console.log("visibleDomains.filter((x:any) => x.layername === wfsLayer.name ) :",visibleDomains.filter((x:any) => x.layername === wfsLayer.name ).find((el:any)=> el.domainId === style.value_id));
                }
            }
        } else {
            if (findedStyle && (findedStyle.type === 'linestring' || findedStyle.type === 'polygon')) {
                wfsLayer.setStyle({
                    lineWidth: findedStyle.stroke_width,
                    lineColor: findedStyle.stroke_color,
                    fillColor: findedStyle.fill_color,
                    fillOpacity: findedStyle.fill_opacity,
                    lineOpacity: findedStyle.stroke_opacity
                });
            }
            else if (findedStyle && findedStyle.type === 'point') {
                wfsLayer.setStyleKey('styleID');
                const image = new Image();
                image.setAttribute('crossorigin', 'anonymous');
                if (findedStyle.icon === null) {

                    const canvas = document.createElement('canvas');
                    const canvasWidhtHeight = 40
                    const R = canvasWidhtHeight + (2 * findedStyle.stroke_width)
                    canvas.height = R;
                    canvas.width = R;
                    const context: any = canvas.getContext('2d');
                    if (findedStyle.shape === 'circle') {
                        context.beginPath();
                        context.lineWidth = findedStyle.stroke_width;
                        context.arc(R / 2, R / 2, (R / 2 - findedStyle.stroke_width), 0, 2 * Math.PI);
                        context.fillStyle = findedStyle.fill_color;
                        context.strokeStyle = findedStyle.stroke_color;
                        if (findedStyle.stroke_width !== 0) {
                            context.stroke();
                        }
                        context.fill();
                    } else if (findedStyle.shape === 'square') {
                        canvas.height = canvasWidhtHeight + findedStyle.stroke_width + findedStyle.stroke_width;
                        canvas.width = canvasWidhtHeight + findedStyle.stroke_width + findedStyle.stroke_width;
                        context.beginPath();
                        context.lineWidth = findedStyle.stroke_width;
                        context.rect(findedStyle.stroke_width, findedStyle.stroke_width, canvasWidhtHeight, canvasWidhtHeight);
                        context.fillStyle = findedStyle.fill_color;
                        context.strokeStyle = findedStyle.stroke_color;
                        if (findedStyle.stroke_width !== 0) {
                            context.stroke();
                        }
                        context.fill();
                    } else if (findedStyle.shape === 'triangle') {
                        context.beginPath();
                        context.moveTo(findedStyle.stroke_width, R - findedStyle.stroke_width);
                        context.lineTo((canvasWidhtHeight / 2) + findedStyle.stroke_width, findedStyle.stroke_width);
                        context.lineTo(R - findedStyle.stroke_width, R - findedStyle.stroke_width);
                        context.lineWidth = findedStyle.stroke_width;
                        context.fillStyle = findedStyle.fill_color;
                        context.strokeStyle = findedStyle.stroke_color;
                        context.closePath();
                        if (findedStyle.stroke_width !== 0) {
                            context.stroke();
                        }
                        context.fill();
                    }
                    // console.log('data:image/' + canvas.toDataURL());

                    image.src = 'data:image/' + canvas.toDataURL().slice(11, canvas.toDataURL().length);

                } else {
                    image.src = 'data:image/' + findedStyle.icon;
                }


                const texture = new Three.Texture(image);
                texture.minFilter = Three.LinearFilter;
                image.onload = (params) => {
                    const height = findedStyle.height;
                    const width = findedStyle.width;
                    const xoffset = findedStyle.width * findedStyle.offset_x;
                    const yoffset = findedStyle.height * findedStyle.offset_y;
                    const geom = new Three.PlaneBufferGeometry(width, height, 1, 1);
                    texture.needsUpdate = true;
                    wfsLayer.setStyle({
                        texture,
                        width,
                        height,
                        xoffset,
                        yoffset,
                        geometry: geom,
                    });
                };
            }
        }
        try {
            pano.getScalable().addLayer(wfsLayer);
            wfsLayer.setMouseEnable(false);
             
            wfsLayer.setLabelVisibility(findedStyle['text_is_text']);
            if (findedStyle['text_fieldname'])
            {
                wfsLayer.setLabelTextSample(findedStyle['text_fieldname']);
                if (findedStyle["text_fieldname"] === 'textFix')
                {
                    wfsLayer.setLabelTextDomain({0:findedStyle["text_fixtext"]});
                }
                else{
                    let domainIds =  visibleLayerDict[wfsLayer.name].find((x: any) => x.name === wfsLayer.__labelTextSample)
                    
                    if (domainIds )
                       { 
                        let domainDict:any = {}
                        for (const item of domainIds.domain) {
                            domainDict[item.id] = item.desc;
                        }
                        wfsLayer.setLabelTextDomain(domainDict);
                    }
                    else
                     { wfsLayer.setLabelTextDomain({}); }
                }
                
            }
            else{
                wfsLayer.setLabelTextSample('');
            }
            
            wfsLayer.redraw(true);
            wfsLayer._viewDistance = 100;
            wfsLayer.setDirty();
            // pano.getScalable()?.getMainSketchLayer().clearAll();
            // pano.getScalable().getMainSketchLayer().refresh();
        } catch (error) {

        }

        if (buttonState === ButtonState.SELECTION) {
            const layers = pano.getScalable().getLayers();
            layers?.find((layer: any) => {
                if (layer._events.onClickFeature?.length > 0) {
                    layer.removeEvent(AnkaScalable.GeoServerWFSLayer.FEATURE_CLICK, selectGdhFeature)
                }
                layer.setMouseEnable(true)
                layer.addEvent(AnkaScalable.GeoServerWFSLayer.FEATURE_CLICK, null, selectGdhFeature)
            })
        }
    }

    useEffect(() => {
        if(pano){
            clearInterval(myInterval);
            // console.log("videoSpeedValue :",videoSpeedValue);
       
       
            pano?.getTilePlugin().resetForPlay()

            // const myInterval_ = setInterval(() => {
            //     goToLocationByFrame(1)
            //     // pano.gotoNextFrame() 
            // }, videoSpeedValue);
            // setMyInterval(myInterval_)
        }
        return ()=>{

            // clearInterval(myInterval);
            pano?.getTilePlugin().stopPlaying()
        }
    }, [videoSpeedValue]);

    function setVideoSpeed(value: any) {
        let _speed = (100 * 960 / value) + 50;
        // (window as any).pano.setBrightness(calcBrightness);
        if(_speed > 5000){
            _speed = (_speed / 200) + 5000
        }
        setVideoSpeedValue(_speed)
        setSpeed(value);

        // console.log(Math.abs(speed/106) * 3);
                                    
        pano.getTilePlugin().fps = Math.abs(value/106) * 3;


    }
    /**
     * Panorama parlaklık ayarı.
     */
    function setBrightnessValue(value: any) {
        const calcBrightness = (4 * value) / 100;
        (window as any).pano.setBrightness(calcBrightness);
        setBrightness(value)
    }
    function setOpacityValue(value: any) {
        const calcOpacity = (value) / 100;
        (window as any).pano.getCloudInstance().oppacity = calcOpacity;
        setOpacity(value)
    }
    function setPointSizeValue(value: any) {
        const pSize = (value) / 10;
        (window as any).pano.getCloudInstance().pSize= pSize;
        setpSize(value)
    }
    function setTimeFilter(value: any) {
       
        (window as any).pano.getCloudInstance().timeFilter= value;
        (window as any).pano.getCloudInstance().enablePoints=false;
        (window as any).pano.getCloudInstance().enablePoints=true;
        
    }
    const yolIdChangeHandler = (point: any) => {
        if (pano) {
            pano.setUrlFactory(Object.assign({}, AnkaPanAPI.PanoGL.URLFactory.prototype, {
                getRequestURL: (pano: any, lon: any, lat: any) => {
                    return pano.jsonConenction + lon + '/' + lat + '/' + point.id + '/false' + `?token=${getToken()}`;
                }
            }));
            pano.gotoLocation(point.lat, point.lon);
        }
    }

    const onSliderValueChange = (value: any, index: number) => {
        const copySettings = [...settings]
        copySettings[index] = { ...copySettings[index], value: value }
        setSettings(copySettings)
    }

    useEffect(() => {
        if (cancelDigiActivate) {

            dispatch(setCancelDigiActivate(false));
            // dispatch(setButtonState(ButtonState.NONE))
            // console.log("clearAll()");
            if (selectedGdhFeature && pano) {
                // console.log("btn : ",buttonState);
                // console.log("cancelDigiActivate selectedGdhFeature :",selectedGdhFeature);
                const st = pano.getScalable().getMainSketchLayer().getGeomListClone()[pano.getScalable().getMainSketchLayer().getGeomListClone().length-1]
                if(buttonState===ButtonState.ADD){
                    pano.getScalable().getMainSketchLayer().removeFromGlobal(st)
                    pano.getScalable().getMainSketchLayer().refresh();
                }
                /*       if(st){
                          console.log("removeFromGlobal");
                          pano.getScalable().getMainSketchLayer().removeFromGlobal(st)
                          // pano.getScalable().getMainSketchLayer().removeFromGlobal(selectedGdhFeature)
                          pano.getScalable().getMainSketchLayer().refresh();
                      } */

                if (buttonState === ButtonState.MOVE) {
                    const st2 = pano.getScalable().getMainSketchLayer().getGeomListClone()[pano.getScalable().getMainSketchLayer().getGeomListClone().length - 1]
                    if (st2 && uidNotFind(st2)) {
                        pano.getScalable().getMainSketchLayer().removeFromGlobal(st2)
                        // pano.getScalable().getMainSketchLayer().removeFromGlobal(selectedGdhFeature)
                        pano.getScalable().getMainSketchLayer().refresh();
                    }
                }
            } else {
                /**
                 * 
                 */
                // if(buttonState !== ButtonState.VERTEX){
                const st = pano.getScalable().getMainSketchLayer().getGeomListClone()[pano.getScalable().getMainSketchLayer().getGeomListClone().length-1]
                if(st && uidNotFind(st)){
                    pano.getScalable().getMainSketchLayer().removeFromGlobal(st)
                    pano.getScalable().getMainSketchLayer().refresh();
                }
                // }
            }
            // pano.getScalable()?.getMainSketchLayer().clearAll();
            // setTimeout(() => {
            //     setSelectedGdhFeature(null)
            // }, 100);
        }
    }, [cancelDigiActivate])

    // 5er 5er ilerletmek için
    const goToLocationByFrame = (step: number) => {
        const cp = pano.getCurrentPoint();
        const newPoint = AnkaPanAPI.Utils.destinationPoint(cp.lat, cp.lon
            , AnkaPanAPI.PanoGLV2.GLOBAL_CONFIG.RECORD_DISTANCE * step, cp.heading);
        pano.gotoLocation(newPoint.lat, newPoint.lon);
        // if((window as any).prevlat && (window as any).prevlon && +(window as any).prevlat === newPoint.lat && +(window as any).prevlon === newPoint.lon){
        //     console.log("true ,");

        //     setVideoStart(false);
        //     clearInterval(myInterval);
        // }else{
        //     (window as any).prevlat=newPoint.lat;
        //     (window as any).prevlon=newPoint.lon;
        //     // setPrevPoint({lat: newPoint.lat, lon: newPoint.lon})
        //     pano.gotoLocation(newPoint.lat, newPoint.lon);
        // }
        
    }

    //klavye ile panoramayı ileri geri yapmak için 
    const handleKeyPress = (event: any) => {

        const key: string = event.code;
        if (key === 'ArrowUp' || key === 'ArrowDown' || key === 'ArrowRight' || key === 'ArrowLeft') {
            event.preventDefault();
            event.stopPropagation();
            if (pano) {
                switch (key) {
                    case 'ArrowUp':
                        pano.gotoNextFrame();
                        break;
                    case 'ArrowDown':
                        pano.gotoPrevFrame();
                        break;
                    case 'ArrowRight':
                        goToLocationByFrame(5);
                        break;
                    case 'ArrowLeft':
                        goToLocationByFrame(-5);
                        break;
                    default:
                        break;
                }
            }
        }
    }

    // useEffect(() => {
    //     if (wfsLayer && features) {
    //         wfsLayer.redraw(true);
    //     }
    // }, [features, panoramaTransactStatus])

    const onInputChanged = async (e: any, index: number) => {
        onSliderValueChange(e.value, index)
    }

    const downloadAsAnkFile = () => {
        const blob = new Blob([` {"roll":${settings[1].value},"pitch":${settings[2].value},"camheight":${settings[3].value},"heading":${settings[0].value}}`],
            { type: "text/plain" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.download = "setup.ank";
        link.href = url;
        link.click();
    }

    const downloadLidarData = async (radius: number) => {
        // const lon = this.location.lon;
        // const lat = this.location.lat;
        let token;
        if (window.localStorage) {
            token = (localStorage as any).token;
        }
        const response = await PointCloudRestApi.getPointCloudData(panoramaCoordinate.lon, panoramaCoordinate.lat, radius)

        // window.open(`${environment.panoHost}gtbys/pc_viewer-${dbID}-${lon}-${lat}-${this.distancePC}.html?token=${this.token}`);
        // window.open(`Point(${panoramaCoordinate.lat} ${panoramaCoordinate.lon})&radius=${radius}`);
    }

    const handleErrorMessage = (message: string) => {
        if (message === 'You do not have the necessary permission!') {
            setIsPanoAvailable(false);
        }
        const messageToShow = (message === 'You do not have the necessary permission!')
            ? t('MESSAGE.permissionDeniedMessage')
            : message;

        toast.current?.show({ summary: (t('TEXT.Warning')), severity: 'info', detail: messageToShow, life: 200 });
    };
    const colorTypeChange = (value: any) => {
        pano.getCloudInstance().colorType = +value;
        setColorType(+value)
    }

    const copyLink = ()=> {
        try {
          const text = document.createElement('textarea');
          text.innerText = window.location.href;;
          document.body.appendChild(text);
          text.select();
          document.execCommand('copy');
          document.body.removeChild(text);
          toast.current?.show({ summary: (`Link copied `), severity: 'success', life: 1000 });
        //   alert(`Link copied ` + window.location.href);
        } catch (error) {
          console.warn(error);
          alert(`Could not copy link`);
        }
      }

    /* *Parlaklık(Brightness)
    *Katman Görünürlüğü(Layer Visibility)
    *Nokta Bulutu(Point Cloud)
    *Nokta Bulutu İndirme(Point Cloud Export)
    *Kalibrasyon(Calibration) */
    return (
        <>
            {!isPanoAvailable && <div className='pano-not-available' style={{ zIndex: 100, position: 'absolute', backgroundColor: "#304562", width: "100%", height: "100%" }}>
                <div>
                    <i className="pi pi-lock" style={{ fontSize: '10rem', color: '#17212f' }}></i>
                    <div className='unauthorized-view' >
                        {language === 'tr' ? (
                            <h3 style={{ fontSize: '2rem', color: '#17212f' }}>Bu alanda yetkiniz bulunmamaktadır. Lütfen yetkili olduğunuz alana geçiniz.</h3>
                        ) : (
                            <h3 style={{ fontSize: '2rem', color: '#17212f' }}>Access to this area is restricted. Please go to the area you are authorized to do. </h3>
                        )}
                    </div></div></div>}
            {isPanoAvailable && !isPanoMiniScreen && <div className='pano-button-group'>
                <div className={brightnessVisibility ? 'brightness2' : 'brightness'}>
                    {brightnessVisibility && <input type="range" className='styled-slider' min="0" max="100" value={brightness} onInput={(e: any) => setBrightnessValue(e.target.value)
                    } />}
                    <Tooltip position="left" target={bright.current} content={t("BUTTON.Brightness")} />
                    <img ref={bright} src="icons/brightness.svg" alt="" onClick={() => {
                        setBrightnessVisibility(!brightnessVisibility)
                    }} />
                </div>
                <div className={viewVisibility ? 'view2' : 'view'}>
                    {viewVisibility && <input type="range" className='styled-slider' min="500" max="9000" step={100} value={view} onInput={(e: any) => setView(e.target.value)} />}

                    <Tooltip position="left" target={tooltip.current} content={t("BUTTON.Layer Visibility")} />

                    <img src="icons/view.svg" alt="" width="17px" ref={tooltip} className="tooltip-image" onClick={() => {
                        setViewVisibility(!viewVisibility)
                    }} />




                </div>

                <div className={lidarVisibility ? 'lidar2' : 'lidar'}>
                    {lidarVisibility &&
                        <div className='label'>
                            <Checkbox inputId="binary" checked={lidarChecked} onChange={(e: any) => showPointCloud(e.checked)} />
                            <span className='text'>{t("BUTTON.Point Cloud")}</span>
                        </div>}
                    <Tooltip position="left" target={cloud.current} content={t("BUTTON.Point Cloud")} />
                    <img src="icons/lidar.svg" alt="" width="17px" ref={cloud} className="tooltip-image" onClick={() => setLidarVisibility(!lidarVisibility)} />
                </div>
                <div className='download'>

                    <Tooltip position="left" target={exportCloud.current} content={t("BUTTON.Point Cloud Export")} />
                    <img src="icons/download.svg" alt="" ref={exportCloud} width="17px" onClick={() => downloadLidarData(15)} />
                </div>
                <div className='settings'>
                    <Tooltip position="left" target={calibration.current} content={t("BUTTON.Calibration")} />
                    <img ref={calibration} src="icons/settings.svg" alt="" width="17px" onClick={() => {
                        if (settingsVisibility) {
                            setIndexAr([])
                            setLastElement(undefined);
                        }
                        setSettingsVisibility(!settingsVisibility)
                    }} />
                </div>

                {lidarChecked && lidarVisibility && <div className='lidar-container'>

                    <div className="tooltip-image">
                       <span className='text'>{t("BUTTON.Color Scale")}</span>

                        {
                            colorLdrSettings.map((setting: any, index: any) =>
                                <div key={setting.label} className="line">
                                    <label htmlFor="">{setting.label}</label>
                                    <input onChange={(e: any) => colorTypeChange(e.target.value)}
                                        type="radio" name="screen-type" checked={colorType === +setting.value ? true : false}
                                        value={setting.value} className="form-radio" />
                                </div>
                            )
                        }
                    </div>
                    <div className="right-block" >
                        <div className="tooltip-image2">
                            <span className='text2'>{t("BUTTON.Opacity")}</span>

                            <input type="range" className='styled-slider' min="0" max="100" value={opacity} onInput={(e: any) => setOpacityValue(e.target.value)
                            } />
                        </div>
                        <div className="tooltip-image2">
                            <span className='text2'>{t("BUTTON.Point Size")}</span>

                            <input type="range" className='styled-slider' min="0" max="100" value={pSize} onInput={(e: any) => setPointSizeValue(e.target.value)
                            } />
                        </div>

                        <div className="tooltip-image2">
                            <span className='text2'>{t("BUTTON.Time Filter Pano Time")}</span>

                            <input type="checkbox" className='checkBox' name=""  onInput={(e: any) => setTimeFilter(e.currentTarget.checked)
                            } />
                        </div>
                     </div>
                </div>}


                {settingsVisibility && <div className='settings-container'>

                    <div className="tooltip-image">
                        {
                            settings.map((setting: any, index: any) =>
                                <div className="line">
                                    <label htmlFor="">{setting.label}</label>
                                    <Slider
                                        min={setting.minValue}
                                        max={setting.maxValue}
                                        step={0.01}
                                        value={setting.value}
                                        onChange={(e: any) => onSliderValueChange(e.value, index)}
                                    />
                                    <InputNumber
                                        inputId={`minmaxfraction${index}`}
                                        mode='decimal'
                                        step={0.01}
                                        locale="en-US"
                                        minFractionDigits={1}
                                        maxFractionDigits={2}
                                        value={setting.value}
                                        onChange={(e: any) => onInputChanged(e, index)}
                                        className='input-style'
                                        name={setting.label}
                                        onFocus={(e: any) => { onFocusChanged(e, index) }}
                                    />

                                </div>

                            )
                        }
                        <div className='button-container'>
                            <Button tooltip="Blue Gray" tooltipOptions={{ className: 'bluegray-tooltip', position: 'left' }} label={t("BUTTON.Export")} className='download-button' onClick={downloadAsAnkFile}></Button>
                        </div>
                    </div>

                </div>}
            </div>}
            {
                    /*isMobileScreen*/ shareBox && 
                    <div className='pano-share-group'> 
                        <div className="share-container">
                            <div className="body">
                                {/* <input type="text" value={window.location.href} /> */}
                                <div className="copylink-group">
                                <div className="copy-link" onClick={ ()=>{
                                     copyLink() 
                                    }} 
                                >
                                    <img style={{ width:"24px", height:"24px"}} src="icons/copy-link.png"></img>
                                    <span style={{marginTop: "7px"}}>Copy Link</span>
                                </div>
                                <button type="button" className="close-button" 
                                onClick={()=>{
                                    setShareBox(false)
                                }} 
                                 /*(click)="closeShareBox()"*/  >&times;</button>
                                </div>
                            </div>
                         </div>
                    </div>
                }
                
            {isPanoAvailable && !isPanoMiniScreen && isMobileScreen && <div className='pano-button-group'>
                <div className='mobile-switch-button' style={{ backgroundColor: isPanoScreen ? '#20202a' : '#20202a'}} onClick={() => dispatch(setIsPanoScreen(!isPanoScreen))}>
                    <img src={isPanoScreen ? "icons/mobile-switch-map.svg" : "icons/mobile-switch-button.svg" } alt="" />
                </div>
                </div>
            }

            { isPanoAvailable && !isPanoMiniScreen && /*isPanoScreen &&*/
                <div className='pano-button-group2'>
                    <div className='mobile-switch-button2' style={{ backgroundColor: videoStart ? '#64B5F6' : '#20202a'}} 
                    onClick={() =>{
                        setVideoStart(!videoStart);
                        ffInterval()
                        /*dispatch(setIsPanoScreen(!isPanoScreen))*/}}>
                        <img className='play-button' src={ videoStart ? "icons/videoPause.svg" : "icons/measureComplete.svg"}  alt="" />
                    </div>
                    {videoStart && <>  
                        <div className='mobile-slider'> 
                            
                            <input type="range" step={106} className='styled-slider' min="106" max="636" value={speed} 
                                onInput={(e: any) => {
                                    setVideoSpeed(e.target.value)
                                }}
                            />
                        </div>
                        <h5 className='slider-text' >{Math.abs(speed/106)}X</h5>
                        </>
                    }
                </div>
            }
            {isPanoAvailable && !isPanoMiniScreen /*&& isMobileScreen */&& <div className='pano-button-group3'>
                <div className='mobile-share-button' style={{ backgroundColor: isPanoScreen ? '#20202a' : '#20202a'}}
                    onClick={()=>{
                        setShareBox(!shareBox)
                        }}
                    >
                    <img src="icons/shareButton.svg" alt="" />
                    </div>
                </div>
            }

            {buttonState === ButtonState.FULLSCREEN && isPanoMiniScreen && <div className="screen-switch-button" onClick={() => dispatch(setIsPanoMiniScreen(!isPanoMiniScreen))}>
                <img src="icons/screen-switch-button.svg" alt="" />
            </div>}
            <Splitter
                position="horizontal"
                primaryPaneMaxWidth="80%"
                primaryPaneMinWidth={0}
                primaryPaneWidth="50%"
                primaryPaneHeight="40%"
                primaryPaneMinHeight={0}
                primaryPaneMaxHeight="100%"
                maximizedPrimaryPane={!isMultiOpen}
                postPoned={false}
                className={`vsplitterPano aa ${isMultiOpen ? 'bb' : ''}`}
            >
                {/* <div id='panodiv' className={`pano ${isMultiOpen ? 'pano-multi-display-active' : ''}`} tabIndex={0} onKeyDown={handleKeyPress}> */}
                {<div id='panodiv' className={`pano ${isMultiOpen ? 'pano-multi-display-active' : ''}`} tabIndex={0} onKeyDown={handleKeyPress}
                    onContextMenu={(e: any) => {
                        // console.log("(window as any).buttonState : ",(window as any).buttonState );
                        const btnState = (window as any).buttonState !== ButtonState.VERTEX && (window as any).buttonState !== ButtonState.MOVE
                        // console.log("eventStatus :", eventStatus);
                        if (btnState && !eventStatus?.isSuccessful && !digiPopupVisibility) {
                            dispatch(setButtonState(ButtonState.NONE))
                            dispatch(setDrawState({ drawStateType: DrawStateType.NONE }))
                            setEventStatus(null)
                        } else if ((window as any).drawStartOfDigi && digiPopupVisibility && btnState && !eventStatus?.isSuccessful) {
                            // dispatch(setButtonState(ButtonState.NONE))
                            dispatch(setDrawState({ drawStateType: DrawStateType.NONE }))
                            setEventStatus(null)
                        }
                    }
                    }
                // style={{ visibility: id === 'panodiv' && !isPanoMiniScreen ? 'visible' : 'hidden' }}
                >

                </div>}
                {/* </div> */}
                <Splitter
                    position="horizontal"
                    primaryPaneMaxWidth="80%"
                    primaryPaneMinWidth={0}
                    primaryPaneWidth="50%"
                    // primaryPaneHeight="40%"
                    primaryPaneMinHeight={0}
                    primaryPaneMaxHeight="100%"
                >
                    <div id="panodiv2" className='pano2'
                        onContextMenu={(e: any) => {
                            if (!eventStatus?.isSuccessful && !digiPopupVisibility) {
                                dispatch(setButtonState(ButtonState.NONE))
                                dispatch(setDrawState({ drawStateType: DrawStateType.NONE }))
                                setEventStatus(null)
                            }
                        }}
                    ></div>
                    <div id="panodiv3" className='pano3'
                        onContextMenu={(e: any) => {
                            if (!eventStatus?.isSuccessful && !digiPopupVisibility) {
                                // console.log("eventStatus :",eventStatus);
                                dispatch(setButtonState(ButtonState.NONE))
                                dispatch(setDrawState({ drawStateType: DrawStateType.NONE }))
                                setEventStatus(null)
                            }
                        }}
                    ></div>
                </Splitter>
            </Splitter >

            {!isPanoMiniScreen && <div style={{ position: 'absolute', right: '10px', bottom: '10px' }}>
                {
                    closestPoints.length > 1 &&
                    closestPoints.map((point: any) => { return <Button style={{ marginRight: '10px' }} onClick={() => { yolIdChangeHandler(point) }}>{new Date(point.date).toLocaleDateString('tr-TR')}</Button> })
                }
            </div>}

            {/* <div id="graphic"
                style={{ overflow: 'scroll', width: '700px', height: '500px', backgroundColor: 'aliceblue', visibility: `${chartVisibility ? 'visible' : 'hidden'}`, position: 'absolute', top: '0px', left: '0px' }}>
                <svg></svg>
            </div > */}
            {
                chartVisibility && cropData && cropData.length > 0 &&
                <RoadProfile data={cropData} />
            }
            <Toast ref={toast} position='bottom-center' className='login-toast' style={{ zIndex: '9 !important' }} />
            <>
                {/* {console.log("xar",drawEnd && digiPopupVisibility && (buttonState !== ButtonState.VERTEX && buttonState !== ButtonState.MOVE) )}  */}
                {drawEnd && digiPopupVisibility && from === 'Panorama' && (buttonState !== ButtonState.VERTEX && buttonState !== ButtonState.MOVE) && <DigiPopup mode={Mode.INSERT} title='Add New Feature' from='Panorama' />}
            </>
            <>
                {/* {console.log("xar2",digiPopupVisibility && updatedOlFeature && (buttonState === ButtonState.VERTEX || buttonState === ButtonState.MOVE) )}           */}
                {digiPopupVisibility && updatedOlFeature && from === 'Panorama' && (buttonState === ButtonState.VERTEX || buttonState === ButtonState.MOVE) && <DigiPopup title='Veri Düzenle' content={`${t('CONFIRMATION.Save')}`} from='Panorama' mode={Mode.UPDATE} canceled={canceled} />}
            </>
            {panoramaTransactStatus && featureCoordinates && (buttonState === ButtonState.ADD || (window as any).drawWithDigi) && <TransactWfs feature={digiOlFeature} coordinates={featureCoordinates} mode={Mode.INSERT} type={featureType} />}
            {panoramaTransactStatus && updatedOlFeature && (buttonState === ButtonState.VERTEX || buttonState === ButtonState.MOVE) && <TransactWfs finish={() => {
                // console.log(" finish ",selectedGdhFeature);
                /*    pano.getScalable().getMainSketchLayer().removeFromGlobal(selectedGdhFeature)
               //    pano.getScalable()?.getMainSketchLayer().clearAll();
                   pano.getScalable().getMainSketchLayer().refresh(); */

                setTimeout(() => {
                    setSelectedGdhFeature(null);
                    setUpdatedOlFeature(null);
                }, 100);

                // setSelectedGdhFeature(null);
            }} feature={updatedOlFeature} coordinates={updatedFeatureCoordinates} mode={Mode.UPDATE} type={updatedFeatureType} />}
        </>
    )
}