import { useEffect } from 'react';
import Overlay from 'ol/Overlay';

interface Props {
    source: any;
    overlays: React.MutableRefObject<Overlay[]>; 
    map: any; 
}

export const ClearAll = (props: Props) => {
    useEffect(() => {
        props.source.clear();

        if (props.overlays.current.length > 0) {
            props.overlays.current.forEach((overlay) => props.map.removeOverlay(overlay));
            props.overlays.current.length = 0;
        }
    }, [props.source, props.overlays, props.map]);

    return null;
};
